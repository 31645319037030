import React from 'react';
import { loadTokens } from '../pages/Developer/Helper';
import { removePayload, getPayload, getFullPayload, getData, setPayload, urlParam } from '../helper';

class NavBarComponent extends React.Component {
    constructor(props) {
        super();
        this.state = {
            user_data: null,
            user_companies: []
        }
        this.loadCompanyData = this.loadCompanyData.bind(this);
    }

    async componentDidMount() {
        try {
            const userData = getPayload('user');
            if(!userData || userData.active !== 1) {
                window.location.href='/login';
                return;
            }
            this.setState({user_data:  userData});
            const currentUrl = urlParam(1);

            // pan card number details
            const panDetails = userData && userData.pan_card ? userData.pan_card : null;

            // if the pan_card number is not present, then send the user to user update screen
            if(!panDetails.number) {
                if(currentUrl !== 'user-update')
                    window.location.href = '/user-update';
            } else if(!panDetails.image_back || !panDetails.image_front) {
                if(currentUrl !== 'user-kyc')
                    window.location.href = '/user-kyc';
            } else {
                this.loadCompanyData();
            }

        } catch(err) {
            throw new Error(err.message);
        }
    }

    async loadCompanyData() {
        try {
            let userCompanies = getPayload('companies');
            if(!userCompanies) {
                const response = await getData('/companies');
                if(response && response.data && response.data.companies && response.data.companies.length > 0) {
                    userCompanies = response.data.companies
                    const primaryCompany = userCompanies[0].company;
                    await loadTokens(primaryCompany.company_id);
                    setPayload(userCompanies, 'companies');
                    if(primaryCompany.assigned_domain && window.location.hostname !== primaryCompany.assigned_domain && window.location.hostname !== 'localhost') {
                        const fullPayload = getFullPayload();
                        const redirectPath = `https://${primaryCompany.assigned_domain}/login?token=${fullPayload}`;
                        removePayload();
                        window.location.href = redirectPath;
                        return;
                    }
                }
            }
            if(!userCompanies) {
                const currentUrl = urlParam(1);
                if(currentUrl !== 'company-add') {
                    window.location.href='/company-add';
                    return;
                }
            }
            this.setState({user_companies:  userCompanies});
        } catch(err) {
            throw new Error(err.message);
        }
    }

    onLogoutHandle = (e) => {
        try {   
            e.preventDefault();
            removePayload();
            window.location.href='/login';

        } catch(err) {
            throw new Error(err.message);
        }
    }

    render() {
        return (
            <>
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                    <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                        <i className="fa fa-bars"></i>
                    </button>

                    <form
                        className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                        <div className="input-group">
                            <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..."
                                aria-label="Search" aria-describedby="basic-addon2"/>
                            <div className="input-group-append">
                                <button className="btn btn-primary" type="button">
                                    <i className="fas fa-search fa-sm"></i>
                                </button>
                            </div>
                        </div>
                    </form>

                    <ul className="navbar-nav ml-auto">
                        <div className="topbar-divider d-none d-sm-block"></div>

                        <li className="nav-item dropdown no-arrow">
                            <a className="nav-link dropdown-toggle" href='/' id="userDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                                    {this.state.user_data ? this.state.user_data.first_name : ''} {this.state.user_data ? this.state.user_data.last_name : ''}
                                </span>
                                <img className="img-profile rounded-circle" alt=''
                                    src="https://i2.wp.com/www.cssscript.com/wp-content/uploads/2020/12/Customizable-SVG-Avatar-Generator-In-JavaScript-Avataaars.js.png?fit=438%2C408&ssl=1"/>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                aria-labelledby="userDropdown">
                                <a className="dropdown-item" href='/user-update'>
                                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Profile
                                </a>
                                <a className="dropdown-item" href='/company'>
                                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Company
                                </a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href='/' data-toggle="modal" data-target="#logoutModal" onClick={this.onLogoutHandle}>
                                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Logout
                                </a>
                            </div>
                        </li>

                    </ul>

                </nav>

            </>
        )
    };
}

export default NavBarComponent;