import React from 'react';
import { loadCategoryOptions } from '../Category/Helper';
import { loadBrandOptions } from '../Brand/Helper';
import { loadUnitOptions } from '../Unit/Helper';
import FormControl from '../../components/FormControl';
import { ErrorToastComponent as ErrorToast } from '../../components/Toasts';
import { postData } from '../../helper';
import { BasicCard } from '../../components/Card';

class AddInventoryPage extends React.Component {
    constructor(props) {
        super();
        this.submitHandler = this.submitHandler.bind(this);
        this.pageContent = this.pageContent.bind(this);
        const self = this;
        this.state = {
            err_message: null,
            form: {
                onSubmit: self.submitHandler
            },
            fields: { 
                inventory_name: {
                    label: 'Item Name',
                    type: 'text',
                    placeholder: 'Name of your item.',
                    value: '',
                    required: true,
                    onChange: self.onChangeHandler
                },
                description: {
                    label: 'Description',
                    type: 'text',
                    placeholder: 'Brief information about your item',
                    value: '',
                    required: true,
                    onChange: self.onChangeHandler
                },
                brand_id: {
                    type: 'select-search',
                    placeholder: 'Brand',
                    required: true,
                    options: {},
                    onChange: self.brandOnChangeHandler
                },
                unit_id: {
                    type: 'select-search',
                    placeholder: 'Unit',
                    required: true,
                    options: {},
                    onChange: self.unitOnChangeHandler
                },
                category_id: {
                    type: 'select-search',
                    placeholder: 'Category',
                    required: true,
                    options: {},
                    onChange: self.categoryOnChangeHandler
                },
                quantity: {
                    label: 'Quantity',
                    type: 'number',
                    placeholder: 'Quantity of Item',
                    value: '',
                    required: true,
                    onChange: self.onChangeHandler
                },
                height: {
                    label: 'Height',
                    type: 'number',
                    placeholder: 'Height of your product',
                    value: '',
                    onChange: self.onChangeHandler
                },
                width: {
                    label: 'Width',
                    type: 'number',
                    placeholder: 'Width of your product',
                    value: '',
                    onChange: self.onChangeHandler
                },
                create_button: {
                    type: 'button',
                    label: 'Add Item',
                    btn_type: 'submit'
                }
            }
        };
    }

    async componentDidMount() {
        try {

            const currentFields = this.state.fields;
            currentFields.category_id.options = await loadCategoryOptions('inventory');
            currentFields.brand_id.options = await loadBrandOptions('inventory');
            currentFields.unit_id.options = await loadUnitOptions('inventory');
            this.setState({fields: currentFields});

        } catch(err) {
            throw new Error(err.message);
        }
    }

    onChangeHandler = (e) => {
        try {
            const fields = this.state.fields;
            fields[e.target.name].value = e.target.value;
            this.setState({fields: fields});
        } catch(err) {
            throw new Error(err.message);
        }
    }

    categoryOnChangeHandler = (e) => {
        try {
            const fields = this.state.fields;
            fields.category_id.value = e.value;
            this.setState({fields: fields});
        } catch(err) {
            throw new Error(err.message);
        }
    }

    unitOnChangeHandler = (e) => {
        try {
            const fields = this.state.fields;
            fields.unit_id.value = e.value;
            this.setState({fields: fields});
        } catch(err) {
            throw new Error(err.message);
        }
    }

    brandOnChangeHandler = (e) => {
        try {
            const fields = this.state.fields;
            fields.brand_id.value = e.value;
            this.setState({fields: fields});
        } catch(err) {
            throw new Error(err.message);
        }
    }

    async submitHandler(payload) {
        try {

            const response = await postData('/inventory', payload);
            if(response.error && response.error.message) {
                this.setState({err_message: response.error.message});
            } else {
                const item = response.data.item ?? {};
                const inventoryID = item.inventory_id ?? null;
                window.location.href = `/inventory?screen=transfer&inventory_id=${inventoryID}`;
                return;
            }

        } catch(err) {
            return new Error(err.message);
        }
    }

    pageContent() {
        try {
            return (
                <>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="card o-hidden border-0">
                                    <div className="card-body p-0">
                                        <div className="p-5">
                                            <FormControl fields={this.state.fields} config={this.state.form}/>
                                            <ErrorToast message={this.state.err_message}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        } catch(err) {
            throw new Error(err.message);
        }
    }

    render() {
        return (
            <>
                <div class='container'>
                    <BasicCard title='New Item' content={this.pageContent(this.props)}/>
                </div>
            </>
        );
    }
}

export default AddInventoryPage;