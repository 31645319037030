import React from 'react';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Country, State, City }  from 'country-state-city';

export function EmailField(props) {
    try {
        return (
            <>
                <div className="form-group">
                    {RequiredLabel(props.config.placeholder, props.config.required, props.config.label)}
                    <input type="email" className="form-control form-control-user"
                        id={props.field} aria-describedby="emailHelp"
                        placeholder={props.config.placeholder} value={props.config.value} 
                        name={props.field} onChange={props.config.onChange}/>
                </div>
            </>
        );
    } catch(err) {
        return err.message;
    }
}

export function TextField(props) {
    try {
        return (
            <>
                <div className="form-group">
                    {RequiredLabel(props.config.placeholder, props.config.required, props.config.label)}
                    <input type="text" className="form-control form-control-user"
                        id={props.field} aria-describedby="textHelp"
                        placeholder={props.config.placeholder} value={props.config.value} 
                        name={props.field} onChange={props.config.onChange}/>
                </div>
            </>
        );
    } catch(err) {
        return err.message;
    }
}

export function PasswordField(props) {
    try {
        return (
            <>
                <div className="form-group">
                    {RequiredLabel(props.config.placeholder, props.config.required, props.config.label)}
                    <input type="password" className="form-control form-control-user"
                        id={props.field} aria-describedby="passwordHelp"
                        placeholder={props.config.placeholder} value={props.config.value} 
                        name={props.field} onChange={props.config.onChange}/>
                </div>
            </>
        );
    } catch(err) {
        return err.message;
    }
}

export function SelectField(props) {
    try {
        const options = props.config.options;
        return (
            <>
                <div className="form-group">
                    {RequiredLabel(props.config.placeholder, props.config.required, props.config.label)}
                    <select className="form-control form-control-user"
                        id={props.field} aria-describedby="passwordHelp"
                        placeholder={props.config.placeholder} value={props.config.value} 
                        name={props.field} onChange={props.config.onChange}>
                            {Object.keys(options).map(function(key, index) {
                                const label = options[key];
                                return <option key={key} value={key}>{label}</option> 
                            })}
                    </select>
                </div>
            </>
        );
    } catch(err) {
        return err.message;
    }
}

export function SelectSelectField(props) {
    try {
        const options = props.config.options;
        const selectOptions = [];
        for(const value in options) {
            const label = options[value];
            selectOptions.push({
                value: value, 
                label: label 
            });
        }
        return (
            <>
                <div className="form-group">
                    {RequiredLabel(props.config.placeholder, props.config.required, props.config.label)}
                    <Select
                    id={props.field} aria-describedby="selecthelp"
                    placeholder={props.config.placeholder} 
                    defaultValue={props.config.value} 
                    name={props.field} onChange={props.config.onChange}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={props.config.isDisabled}
                    isLoading={props.config.isLoading}
                    isClearable={props.config.isClearable}
                    isRtl={props.config.isRtl}
                    isSearchable={props.config.isSearchable}
                    isMulti={props.config.isMulti ?? false}
                    options={selectOptions}/>
                </div>
            </>
        );
    } catch(err) {
        return err.message;
    }
}

export function CreatableSelectField(props) {
    try {
        const options = props.config.options;

        const filterOptions = (inputValue) => {
            console.log(inputValue);
        };
        
        const promiseOptions = (inputValue) => {
            setTimeout(() => {
                filterOptions(inputValue);
            }, 1000);
        };

        console.log(options);
        return (
            <>
                <div className="form-group">
                    {RequiredLabel(props.config.placeholder, props.config.required, props.config.label)}
                    <AsyncCreatableSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={promiseOptions}
                    />
                </div>
            </>
        );
    } catch(err) {
        return err.message;
    }
}

export function CountryField(props) {
    try {
        const country = Country.getCountryByCode('IN');
        const options = {
            [country.isoCode]: [country.name]
        };
        
        return (
            <>
                <div className="form-group">
                    {RequiredLabel(props.config.placeholder, props.config.required, props.config.label)}
                    <select className="form-control form-control-user"
                        id={props.field} aria-describedby="passwordHelp"
                        placeholder={props.config.placeholder} value={props.config.value} 
                        name={props.field} onChange={props.config.onChange}>
                            {Object.keys(options).map(function(key, index) {
                                const label = options[key];
                                return <option key={key} value={key}>{label}</option> 
                            })}
                    </select>
                </div>
            </>
        );
    } catch(err) {
        return err.message;
    }
}

export function StateField(props) {
    try {
        const states = State.getStatesOfCountry('IN');
        const options = {};
        for(const state of states) {
            options[state.isoCode] = state.name;
        }
        return (
            <>
                <div className="form-group">
                    {RequiredLabel(props.config.placeholder, props.config.required, props.config.label)}
                    <select className="form-control form-control-user"
                        id={props.field} aria-describedby="passwordHelp"
                        placeholder={props.config.placeholder} value={props.config.value} 
                        name={props.field} onChange={props.config.onChange}>
                            {Object.keys(options).map(function(key, index) {
                                const label = options[key];
                                return <option key={key} value={key}>{label}</option> 
                            })}
                    </select>
                </div>
            </>
        );
    } catch(err) {
        return err.message;
    }
}

export function CityField(props) {
    try {
        const cities = City.getCitiesOfCountry('IN');
        const options = {};
        for(const city of cities) {
            options[city.name] = city.name;
        }
        return (
            <>
                <div className="form-group">
                    {RequiredLabel(props.config.placeholder, props.config.required, props.config.label)}
                    <select className="form-control form-control-user"
                        id={props.field} aria-describedby="passwordHelp"
                        placeholder={props.config.placeholder} value={props.config.value} 
                        name={props.field} onChange={props.config.onChange}>
                            {Object.keys(options).map(function(key, index) {
                                const label = options[key];
                                return <option key={key} value={key}>{label}</option> 
                            })}
                    </select>
                </div>
            </>
        );
    } catch(err) {
        return err.message;
    }
}

export function Button(props) {
    try {
        return (
            <>
                <div className="form-group">
                    <button type={props.config.btn_type} className="btn btn-success btn-icon-split" onClick={props.config.onClick}>
                        <span className="icon text-white-50">
                            <i className="fas fa-check"></i>
                        </span>
                        <span className="text">{props.config.label}</span>
                    </button>
                </div>
            </>
        );
    } catch(err) {
        return err.message;
    }
}

export function FileField(props) {
    try {
        return (
            <>
                <div className="form-group">
                    {RequiredLabel(props.config.placeholder, props.config.required, props.config.label)}
                    <input type="file" className="form-control form-control-user"
                        id={props.field} aria-describedby="textHelp"
                        placeholder={props.config.placeholder}
                        name={props.field} onChange={props.config.onChange}/>
                </div>
            </>
        );
    } catch(err) {
        return err.message;
    }
}

export function NumberField(props) {
    try {
        return (
            <>
                <div className="form-group">
                    {RequiredLabel(props.config.placeholder, props.config.required, props.config.label)}
                    <input type="number" className="form-control form-control-user"
                        id={props.field} aria-describedby="textHelp"
                        placeholder={props.config.placeholder} value={props.config.value} 
                        name={props.field} onChange={props.config.onChange}/>
                </div>
            </>
        );
    } catch(err) {
        return err.message;
    }
}

function RequiredLabel(text, required=false, label=null) {
    try {
        text = label ?? text;
        if(required)
            return (
                <>
                    <div className="form-group-required">
                        <label className="control-label">{text}</label>
                    </div>
                </>
            );
        else
            return <label className="control-label">{text}</label>;
    } catch(err) {
        return ''
    }
}