import React from 'react';
import FormControl from '../../components/FormControl';
import { ErrorToastComponent as ErrorToast } from '../../components/Toasts';
import { postData, getData, setPayload, primaryCompany, getQueryValue } from '../../helper';

class VerifyCompanyForm extends React.Component {
    constructor(props) {
        super();
        this.submitHandler = this.submitHandler.bind(this);
        const companyID = getQueryValue('company_id');
        const self = this;
        this.state = {
            companyID: companyID,
            err_message: null,
            form: {
                onSubmit: self.submitHandler
            },
            fields: { 
                verification_otp: {
                    type: 'text',
                    placeholder: 'Verification OTP',
                    value: '',
                    required: true,
                    onChange: self.onChangeHandler
                },
                create_button: {
                    type: 'button',
                    label: 'Verify',
                    btn_type: 'submit'
                },
                resend_button: {
                    type: 'button',
                    label: 'Resend OTP',
                    btn_type: 'button'
                }
            }
        };
    }

    componentDidMount() {
        try {
            const companyData = primaryCompany();
            if(companyData.company && companyData.company.active === 1) {
                window.location.href = '/dashboard';
                return;
            }
        } catch(err) {
            throw new Error(err.message);
        }
    }

    onChangeHandler = (e) => {
        try {
            const fields = this.state.fields;
            fields[e.target.name].value = e.target.value;
            this.setState({fields: fields});
        } catch(err) {
            throw new Error(err.message);
        }
    }

    async submitHandler(payload) {
        try {
            const response = await postData(`/companies/${this.state.companyID}/verify`, payload);
            if(response.error && response.error.message) {
                this.setState({err_message: response.error.message});
            } else {
                if(response && response.data && response.data.verified) {
                    const companiesRes = await getData('/companies');
                    if(companiesRes && companiesRes.data && companiesRes.data.companies && companiesRes.data.companies.length > 0) {
                        setPayload(companiesRes.data.companies, 'companies');
                        window.location.href = '/dashboard';
                    }
                }
            }

        } catch(err) {
            return new Error(err.message);
        }
    }

    render() {
        return (
            <>
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-4">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="p-5">
                                    <FormControl fields={this.state.fields} config={this.state.form}/>
                                    <ErrorToast message={this.state.err_message}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default VerifyCompanyForm;