import crypto from 'crypto';
import config from './config';

/**
 * 
 * @param {*} path : api path
 * @param {*} payload : post data
 */
export async function postData(path, payload = null, method = 'POST', headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}) {
    try {
        const apiKey = authorizationKey();
        const token = userToken();
        const apiToken = getCurrentToken();
        headers = {
            ...headers,
            'x-api-key': apiKey,
            'x-amz-security-token': token,
            'x-api-token': apiToken
        };
        const res = await fetch(`${config.api_url}${path}`, {
            method: method,
            body: JSON.stringify(payload), 
            headers: headers
        });
        return res.json();
    } catch (err) {
        console.log(err);
        return;
    }
}

/**
 * 
 * @param {*} path : api path
 * @param {*} payload : post data
 */
 export async function postFormData(path, formData = null, method = 'POST') {
    try {
        const apiKey = authorizationKey();
        const token = userToken();
        const apiToken = getCurrentToken();
        const res = await fetch(`${config.api_url}${path}`, {
            method: method,
            body: formData, 
            headers: {
                'x-api-key': apiKey,
                'x-amz-security-token': token,
                'x-api-token': apiToken
            }
        });
        return res.json();
    } catch (err) {
        return;
    }
}

/**
 * 
 * @param {*} path : api path
 */
export async function getData(path) {
    try {
        const apiKey = authorizationKey();
        const token = userToken();
        const apiToken = getCurrentToken();
        const res = await fetch(`${config.api_url}${path}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': apiKey,
                'x-amz-security-token': token,
                'x-api-token': apiToken
            }
        });
        return res.json();
    } catch (err) {
        return;
    }
}

/**
 * 
 * @param {*} key : key from the url
 */
export function getQueryValue (key) {  
    if(!key)
        return;
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));  
} 

export function currentURL() {
    return window.location.href;
}

/**
 * 
 * @param {*} key : value of the key from the path
 */
export function urlParam(key = null) {
    let pathName = window.location.pathname;
    let value = pathName.split('/');

    // if key is not null
    if(!key)
        return value;
    else
        return value[key] ?? null;
}

// random string generator
export function randomString() {
    return '_' + Math.random().toString(36).substr(2, 9);
}

// set user data
export function setUserData(payload) {
    payload = JSON.stringify(payload);
    let salt = '335d67ab-5e32-43e9-ac8c-99e050c38ad7';
    let key = crypto.createCipher('aes-128-cbc', salt);
    let str = key.update(payload, 'utf8', 'hex')
    str += key.final('hex');
    localStorage.setItem('user', str);
}

// get user data
export function getUserData() {
    let payload = localStorage.getItem('user');
    if(payload) {
        let salt = '335d67ab-5e32-43e9-ac8c-99e050c38ad7';
        let key = crypto.createDecipher('aes-128-cbc', salt);
        let str = key.update(payload, 'hex', 'utf8')
        str += key.final('utf8');
        payload = JSON.parse(str);
    }
    return payload;
}

// get full payload data
export function getFullPayload() {
    let payload = localStorage.getItem('payload');
    return payload;
}

// set full payload data
export function setFullPayload(payload) {
    localStorage.setItem('payload', payload);
}

// set user data
export function setPayload(payload, type = null) {
    let res = getPayload() || {};
    res[type] = payload; 
    payload = JSON.stringify(res);
    let salt = '335d67ab-5e32-43e9-ac8c-99e050c38ad7';
    let key = crypto.createCipher('aes-128-cbc', salt);
    let str = key.update(payload, 'utf8', 'hex')
    str += key.final('hex');
    setFullPayload(str);
}

// get user data
export function getPayload(type = null) {
    let payload = getFullPayload();
    if(payload) {
        let salt = '335d67ab-5e32-43e9-ac8c-99e050c38ad7';
        let key = crypto.createDecipher('aes-128-cbc', salt);
        let str = key.update(payload, 'hex', 'utf8')
        str += key.final('utf8');
        payload = JSON.parse(str);
        payload = !type ? payload : payload[type];
    }
    return payload;
}

// get primary company
export function primaryCompany() {
    try {
        const companies = getPayload('companies');
        if(companies && companies.length > 0) {
            const company = companies[0];
            return company;
        }
        return null;
    } catch(err) {
        throw new Error(err.message);
    }
};

// get primary company
export function getCurrentToken() {
    try {
        const liveModeValue = getPayload('live_mode_value') ?? true;
        // const currentEnv = liveModeValue === true ? 'prod' : 'test';
        const currentEnv = 'system';
        const availableTokens = getPayload('token_data');
        if(availableTokens && availableTokens.length > 0) {
            for(const api of availableTokens) {
                if(api.environment === currentEnv) {
                    return api.token;
                }
            }
        }

        return 'no-token-found';
    } catch(err) {
        throw new Error(err.message);
    }
};

// remove payload
export function removePayload() {
    localStorage.removeItem('payload');
}

//  get usertoken from the stored user data
function userToken() {
    const accessToken = getPayload('access_token');
    if(accessToken) return accessToken;
    return null;
}

export function getURLSearchParams(key) {
    try {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(key);
    } catch(err) {
        return null;
    }
}

// get first charater
export function getFirstCharacter(text) {
    if(text && text.length > 0) 
        return text.charAt(0)
    return '';
}

export function truncateText(text, maxSize = 25) {
    if (text.length > maxSize) {
        text = text.substring(0, 24) + "...";
    }
    return text;
}

export function loggedInRedirection() {
    try {
        const payload = getURLSearchParams('token');
        if(payload) {
            setFullPayload(payload);
        }
        const userData = getPayload('user');
        const isUSerActive = userData && userData.active===1 ? userData.active : 0;
        const isVerified = getPayload('verified') ?? false;
        if(isUSerActive || isVerified) {
            window.location.href = '/dashboard';
            return true;
        }
        return false;
    } catch(err) {
        return false;
    }
}

// generate apiKey
function authorizationKey() {
    return '123123123';
    // try {
    //     let epoch = Math.floor((new Date()).getTime()/1000); 
    //     let key = epoch.toString();
    //     let salt = '335d67ab-5e32-43e9-ac8c-99e050c38ad7';

    //     salt = crypto.createCipher('aes-128-cbc', salt);
    //     key = salt.update(key, 'utf8', 'hex');
    //     key += salt.final('hex');
    //     return key;
    // } catch(err) {
    //     return;
    // }
}
