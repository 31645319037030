import React from 'react';
import OptionBarComponent from './OptionBar';
import NavBarComponent from './NavBar';
import FooterComponent from './Footer';

function DashBoardViewComponent(props) {
    return (
        <>
            <div id="wrapper">
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <NavBarComponent />
                        <OptionBarComponent />
                        <div className="container-fluid">
                            <h1 className="h3 mb-4 text-gray-800">{props.title ?? ''}</h1>
                            {props.content ?? ''}
                        </div>
                    </div>
                    <FooterComponent />
                </div>

            </div>
        </>
    );
}

export default DashBoardViewComponent;