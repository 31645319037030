import React from 'react';
import FormControl from '../../components/FormControl';
import { ErrorToastComponent as ErrorToast } from '../../components/Toasts';
import { postData, getData, setPayload, primaryCompany } from '../../helper';

class AddCompanyForm extends React.Component {
    constructor(props) {
        super();
        this.submitHandler = this.submitHandler.bind(this);
        const self = this;
        this.state = {
            err_message: null,
            form: {
                onSubmit: self.submitHandler
            },
            fields: { 
                company_name: {
                    type: 'text',
                    placeholder: 'Name of Your Business',
                    value: '',
                    required: true,
                    onChange: self.onChangeHandler
                },
                company_email: {
                    type: 'email',
                    placeholder: 'Business email address',
                    value: '',
                    required: true,
                    onChange: self.onChangeHandler
                },
                company_mobile_number: {
                    type: 'text',
                    placeholder: 'Business mobile number',
                    value: '',
                    required: true,
                    onChange: self.onChangeHandler
                },
                about: {
                    type: 'text',
                    placeholder: 'Brief information about your Business',
                    value: '',
                    onChange: self.onChangeHandler
                },
                category: {
                    type: 'select',
                    placeholder: 'Category',
                    value: 'internet',
                    required: true,
                    options: {
                        internet: 'Internet',
                        finance: 'Finance',
                        retail: 'Retail',
                        other: 'Other'
                    },
                    onChange: self.onChangeHandler
                },
                type: {
                    type: 'select',
                    placeholder: 'Type of Business',
                    value: 'individual',
                    required: true,
                    options: {
                        individual: 'Individual',
                        individual1: 'Individual1',
                    },
                    onChange: self.onChangeHandler
                },
                form_seperator: {
                    label: 'You will receive Payouts in the below Account'
                },
                account_number: {
                    type: 'text',
                    placeholder: 'Account Number',
                    value: '',
                    required: true,
                    onChange: self.onChangeHandler
                },
                confirm_account_number: {
                    type: 'text',
                    placeholder: 'Confirm Account Number',
                    value: '',
                    required: true,
                    onChange: self.onChangeHandler
                },
                ifsc_code: {
                    type: 'text',
                    placeholder: 'Account IFSC Code',
                    value: '',
                    required: true,
                    onChange: self.onChangeHandler
                },
                create_button: {
                    type: 'button',
                    label: 'Add Company',
                    btn_type: 'submit'
                }
            }
        };
    }

    componentDidMount() {
        try {
            const companyData = primaryCompany();
            if(companyData && companyData.company) {
                if(companyData.company.active === 1)
                    window.location.href = '/dashboard';
                else
                    window.location.href = `/company-verify?company_id=${companyData.company.company_id}`;
                return;
            }
        } catch(err) {
            throw new Error(err.message);
        }
    }

    onChangeHandler = (e) => {
        try {
            const fields = this.state.fields;
            fields[e.target.name].value = e.target.value;
            this.setState({fields: fields});
        } catch(err) {
            throw new Error(err.message);
        }
    }

    async submitHandler(payload) {
        try {
            const response = await postData('/companies', payload);
            if(response.error && response.error.message) {
                this.setState({err_message: response.error.message});
            } else {
                if(response && response.data && response.data.companies && response.data.companies.company) {
                    const companiesRes = await getData('/companies');
                    if(companiesRes && companiesRes.data && companiesRes.data.companies && companiesRes.data.companies.length > 0) {
                        setPayload(companiesRes.data.companies, 'companies');
                        window.location.href = `/company-verify?company_id=${companiesRes.data.companies[0].company.company_id}`;
                    }
                }
            }

        } catch(err) {
            return new Error(err.message);
        }
    }

    render() {
        return (
            <>
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-4">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="p-5">
                                    <FormControl fields={this.state.fields} config={this.state.form}/>
                                    <ErrorToast message={this.state.err_message}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AddCompanyForm;