import React from 'react';
import { loadBrands } from './Helper';
import { BasicDataTable } from '../../components/DataTable';
import { ErrorToastComponent as ErrorToast  } from '../../components/Toasts';

class BrandListPage extends React.Component {
    constructor(props) {
        super();
        this.state = {
            datatable: {
                columns: [
                    {
                        label: 'Name',
                        field: 'brand_name'
                    },
                    {
                        label: 'Type',
                        field: 'entity_type',
                        width: 150
                    },
                    {
                        label: 'Action',
                        field: 'product_action'
                    }
                ],
                rows: []
            }
        }
    }

    async componentDidMount() {
        try {

            // load sites
            await this.loadAvailableItems(this.props.owner);

        } catch(err) {
            throw new Error(err.message);
        }
    }

    async loadAvailableItems(owner) {
        try {

            const self = this;
            const brands = await loadBrands(owner);

            const productRows = [];
            for(const itemInfo of brands) {
                if(itemInfo.brand) {
                    const product = {
                        brand_name: itemInfo.brand.brand_name,
                        entity_type: itemInfo.brand.entity_type,
                        product_action: self.itemActions(itemInfo.brand.brand_id)
                    };
                    productRows.push(product);
                }
            }
        
            self.setState({
                datatable: {
                    columns: self.state.datatable.columns,
                    rows: productRows
                }
            });

        } catch(err) {
            throw new Error(err.message);
        }
    }

    getCompleteAddress(addresses) {
        try {
            const stringBuffer = [];
            for(const addrKey in addresses) {
                const addrValue = addresses[addrKey];
                stringBuffer.push(addrValue);
            }
            return stringBuffer.join(", ");
        } catch(err) {
            return 'Error in address'
        }
    }

    redirectItemPage(categoryID) {
        try {
            const path = `/inventory?screen=dashboard&warehouse_id=${categoryID}`;
            window.location.href = path;
            return;
        } catch(err) {
            throw new Error(err.message);
        }
    }

    itemActions(brandID) {
        try {
            return (
                <>
                    <button className="btn btn-info btn-circle btn-sm" data-toggle="tooltip" title='Check Brand Information' onClick={() => this.redirectItemPage(brandID)}>
                        <i className="fas fa-info-circle"></i>
                    </button>
                </>
            );
        } catch(err) {
            throw new Error(err.message);
        }
    }

    async createNewBrand() {
        try {
            window.location.href = '/inventory?screen=addbrand';
            return;
        } catch(err) {
            throw new Error(err.message);
        }
    }

    addProductButton() {
        try {
            return (
                <button className="btn btn-success btn-circle btn-sm add-token-btn" data-toggle="tooltip" title='Create new brand' onClick={this.createNewBrand}>
                    <i className="far fa-plus-square"></i>
                </button>
            )
        } catch(err) {
            throw new Error(err.message);
        }
    }

    renderTable() {
        try {
            let table = 'No items available';
            if(this.state.datatable.rows.length > 0) {
                table = <BasicDataTable label='Available Brands' data={this.state.datatable} />;
            }

            return (
                
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Available Brands</h6>
                        {this.addProductButton()}
                    </div>
                    <div className="card-body">
                        {table}
                    </div>
                </div>
            );
        } catch(err) {
            throw new Error(err.message);
        }
    }

    pageContent() {
        try {
            return (
                <>
                    {this.renderTable()}
                </>
            )
        } catch(err) {
            throw new Error(err.message);
        }
    }

    render() {
        return (
            <>  
                <div className='container'>
                    {this.pageContent()}
                    <ErrorToast message={this.state.err_message}/>
                </div>
            </>
        );
    }
}

export default BrandListPage;