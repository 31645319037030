import { setPayload, getPayload } from '../../helper';

export function store(siteID, siteData) {
    try {
        if(siteData && siteData.data && siteData.data.site) {
            const site = { 
                [siteID] : siteData.data.site 
            };
            setPayload(site, 'site');
        }
    } catch(err) {
        throw new Error(err.message);
    }
}

export function fetch(siteID) {
    try {
        const sitePayload = getPayload('site');
        if(sitePayload && sitePayload[siteID])
            return sitePayload[siteID];
        return null;
    } catch(err) {
        throw new Error(err.message);
    }
}