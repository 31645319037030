import React from 'react';
import ConfirmForm from './ConfirmForm';
import { loggedInRedirection, getURLSearchParams } from '../../helper';

function ConfirmOtpPage() {
    const emailRequired = getURLSearchParams('email_id');
    loggedInRedirection();
    return (
        <>  
            <section id='confirm-otp'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-8 col-md-4">
                            <div className="card o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="p-5">
                                                <div className="text-center">
                                                    <h1 className="h4 text-gray-900 mb-4">Verification OTP sent to your Email</h1>
                                                </div>
                                                <ConfirmForm emailRequired={emailRequired}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ConfirmOtpPage;