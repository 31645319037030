import { getData, setPayload } from '../../helper';

export async function loadTokens(companyID) {
    try {

        const apiPath = `/tokens/${companyID}`;
        const response = await getData(apiPath);

        if(response && response.data && response.data.tokens) {
            setPayload(response.data.tokens, 'token_data');
            return response.data.tokens;
        }
        return [];

    } catch(err) {
        throw new Error(err.message);
    }
}