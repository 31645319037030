import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

export function BasicBreadcrumbs(props) {

    const menu = Object.keys(props.breadCrumbs).map(function(item, index) { 
        const menuItem = props.breadCrumbs[item];
        if(menuItem.link)
            return withLink(item, menuItem);
        else
            return noLink(item, menuItem);
    });

    return (
        <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
                { menu }
            </Breadcrumbs>
        </div>
    );
}

function withLink(key, option) {
    try {
        return (
            <Link underline="hover" color="inherit" href={option.link} key={key}>
                { option.label }
            </Link>
        );
    } catch(err) {
        throw new Error(err.message);
    }
}

function noLink(key, option) {
    try {
        return (
            <Typography color="text.primary" key={key}>{ option.label }</Typography>
        );
    } catch(err) {
        throw new Error(err.message);
    }
}