import React from 'react';
import DashBoardViewComponent from '../../components/DashBoardView';
import AddCompanyForm from '../Organisation/AddForm';

function pageContent() {
    try {
        return (
            <>
                <AddCompanyForm/>
            </>
        );
    } catch(err) {
        return '';
    }
}

function OrganisationAddPage() {
    return (
        <>
            <DashBoardViewComponent title='Add Your Business' content={pageContent()}/>
        </>
    );
}

export default OrganisationAddPage;