import { getQueryValue } from '../helper';
import React from 'react';

function SideBarComponent(props) {
    try {
        const options = sideOptions(props.options);
        return (
            <>
                <ul className="navbar-nav sidebar sidebar-dark accordion" id="accordionSidebar">
                    {options}
                </ul>
            </>
        );
    } catch(err) {
        throw new Error(err.message);
    }
}

function sideOptions(options) {
    try {
        return Object.keys(options).map((item, index) => {
            const option = options[item];
            if(option.child && option.child.length > 0) {
                return parentChildOption(item, option)
            }
            if(item === 'lineBreak' && option === true) {
                return lineBreak(item, option);
            }
            return singleOption(item, option);
        });
    } catch(err) {
        throw new Error(err.message);
    }
}

function lineBreak(key, option) {
    try {
        return (
            <hr className="sidebar-divider my-0" />
        );
    } catch(err) {
        throw new Error(err.message);
    }
}


function singleOption(key, option) {
    try {
        const currentScreen = getQueryValue('screen');
        const navItemClass = currentScreen === key ? 'nav-item nav-item-active btn-primary' : 'nav-item';
        return (
            <li className={navItemClass} key={key}>
                <a className="nav-link collapsed" href={option.link}>
                    <span>{option.label}</span>
                </a>
            </li>
        );
    } catch(err) {
        throw new Error(err.message);
    }
}

function parentChildOption(key, option) {
    try {
        return (
            <li className="nav-item" key={key}>
                <a className="nav-link collapsed" href='/' data-toggle="collapse" data-target="#collapseDepartments"
                    aria-expanded="true" aria-controls="collapseDepartments">
                    <span>{option.label}</span>
                </a>
                <div id="collapseDepartments" className="collapse" aria-labelledby="headingDepartments"
                    data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <a className="collapse-item" href="/departments">View All</a>
                        <a className="collapse-item" href="/department-add">Add New</a>
                    </div>
                </div>
            </li>
        );
    } catch(err) {
        throw new Error(err.message);
    }
}

export default SideBarComponent;