import React from 'react';

function FooterComponent() {
    return (
        <>
        <footer className="sticky-footer bg-white">
            <div className="container my-auto">
                <div className="copyright text-center my-auto">
                    <span>Copyright &copy; NeoPayout</span>
                </div>
            </div>
        </footer>
        </>
    )
}

export default FooterComponent;