import { getData } from '../../helper';

export async function loadBrandOptions(type) {
    try {

        const brands = await loadBrands(type);
        const options = {};
        for(let data of brands) {
            options[data.brand.brand_id] = data.brand.brand_name;
        }
        return options;

    } catch(err) {
        return [];
    }
}

export async function loadBrands(type) {
    try {

        const payload = await getData(`/brands/${type}`);
        if(payload && payload.data && payload.data.brands)
            return payload.data.brands;
        return [];

    } catch(err) {
        throw new Error(err.message);
    }
} 