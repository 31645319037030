import React from 'react';
import FormControl from '../../components/FormControl';
import { ErrorToastComponent as ErrorToast } from '../../components/Toasts';
import { postData } from '../../helper';
import { BasicCard } from '../../components/Card';

class AddCategoryPage extends React.Component {
    constructor(props) {
        super();
        this.submitHandler = this.submitHandler.bind(this);
        this.pageContent = this.pageContent.bind(this);
        const self = this;
        this.state = {
            err_message: null,
            form: {
                onSubmit: self.submitHandler
            },
            fields: { 
                category_name: {
                    label: 'Category Name',
                    type: 'text',
                    placeholder: 'Name of your category.',
                    value: '',
                    required: true,
                    onChange: self.onChangeHandler
                },
                create_button: {
                    type: 'button',
                    label: 'Add Category',
                    btn_type: 'submit'
                }
            }
        };
    }

    async componentDidMount() {
        try {

        } catch(err) {
            throw new Error(err.message);
        }
    }

    onChangeHandler = (e) => {
        try {
            const fields = this.state.fields;
            fields[e.target.name].value = e.target.value;
            this.setState({fields: fields});
        } catch(err) {
            throw new Error(err.message);
        }
    }

    async submitHandler(payload) {
        try {

            const reqPayload = { ...payload, ...{entity_type: this.props.owner}};
            const response = await postData(`/categories`, reqPayload);
            if(response.error && response.error.message) {
                this.setState({err_message: response.error.message});
            } else {
                window.location.href = `/inventory?screen=categorylisting`;
                return;
            }

        } catch(err) {
            return new Error(err.message);
        }
    }

    pageContent() {
        try {
            return (
                <>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="card o-hidden border-0">
                                    <div className="card-body p-0">
                                        <div className="p-5">
                                            <FormControl fields={this.state.fields} config={this.state.form}/>
                                            <ErrorToast message={this.state.err_message}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        } catch(err) {
            throw new Error(err.message);
        }
    }

    render() {
        return (
            <>
                <div className='container'>
                    <BasicCard title='New Category' content={this.pageContent(this.props)}/>
                </div>
            </>
        );
    }
}

export default AddCategoryPage;