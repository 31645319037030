import React from 'react';
import { loadWarehouses } from '../Warehouse/Helper';
import { BasicDataTable } from '../../components/DataTable';
import { ErrorToastComponent as ErrorToast  } from '../../components/Toasts';

class WarehouseListPage extends React.Component {
    constructor(props) {
        super();
        this.state = {
            datatable: {
                columns: [
                    {
                        label: 'Warehouse Name',
                        field: 'warehouse_name',
                        width: 150
                    },
                    {
                        label: 'Contact Person',
                        field: 'contact_person'
                    },
                    {
                        label: 'Contact Mobile',
                        field: 'mobile_number'
                    },
                    {
                        label: 'Address',
                        field: 'address'
                    },
                    {
                        label: 'Action',
                        field: 'product_action'
                    }
                ],
                rows: []
            }
        }
    }

    async componentDidMount() {
        try {

            // load sites
            await this.loadAvailableItems();

        } catch(err) {
            throw new Error(err.message);
        }
    }

    async loadAvailableItems() {
        try {

            const self = this;
            const warehouses = await loadWarehouses();

            const productRows = [];
            for(const itemInfo of warehouses) {
                if(itemInfo.warehouse) {
                    const product = {
                        warehouse_name: itemInfo.warehouse.warehouse_name,
                        contact_person: itemInfo.warehouse.contact.contact_person ?? 'No contact person',
                        mobile_number: itemInfo.warehouse.contact.mobile_number ?? 'No contact mobile number',
                        address: self.getCompleteAddress(itemInfo.warehouse.location),
                        product_action: self.itemActions(itemInfo.warehouse.warehouse_id)
                    };
                    productRows.push(product);
                }
            }
        
            self.setState({
                datatable: {
                    columns: self.state.datatable.columns,
                    rows: productRows
                }
            });

        } catch(err) {
            throw new Error(err.message);
        }
    }

    getCompleteAddress(addresses) {
        try {
            const stringBuffer = [];
            for(const addrKey in addresses) {
                const addrValue = addresses[addrKey];
                stringBuffer.push(addrValue);
            }
            return stringBuffer.join(", ");
        } catch(err) {
            return 'Error in address'
        }
    }

    redirectItemPage(warehouseID) {
        try {
            const path = `/inventory?screen=dashboard&warehouse_id=${warehouseID}`;
            window.location.href = path;
            return;
        } catch(err) {
            throw new Error(err.message);
        }
    }

    itemActions(warehouseID) {
        try {
            return (
                <>
                    <button className="btn btn-info btn-circle btn-sm" data-toggle="tooltip" title='Check Warehouse Information' onClick={() => this.redirectItemPage(warehouseID)}>
                        <i className="fas fa-info-circle"></i>
                    </button>
                </>
            );
        } catch(err) {
            throw new Error(err.message);
        }
    }

    async createNewWarehouse() {
        try {
            window.location.href = '/inventory?screen=addnewwarehouse';
            return;
        } catch(err) {
            throw new Error(err.message);
        }
    }

    addProductButton() {
        try {
            return (
                <button className="btn btn-success btn-circle btn-sm add-token-btn" data-toggle="tooltip" title='Create new warehouse' onClick={this.createNewWarehouse}>
                    <i className="far fa-plus-square"></i>
                </button>
            )
        } catch(err) {
            throw new Error(err.message);
        }
    }

    renderTable() {
        try {
            let table = 'No items available';
            if(this.state.datatable.rows.length > 0) {
                table = <BasicDataTable label='Available Warehouses' data={this.state.datatable} />;
            }

            return (
                
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Available Warehouses</h6>
                        {this.addProductButton()}
                    </div>
                    <div className="card-body">
                        {table}
                    </div>
                </div>
            );
        } catch(err) {
            throw new Error(err.message);
        }
    }

    pageContent() {
        try {
            return (
                <>
                    {this.renderTable()}
                </>
            )
        } catch(err) {
            throw new Error(err.message);
        }
    }

    render() {
        return (
            <>  
                <div className='container'>
                    {this.pageContent()}
                    <ErrorToast message={this.state.err_message}/>
                </div>
            </>
        );
    }
}

export default WarehouseListPage;