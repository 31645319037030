require('dotenv').config();

const prodConfig = {
    api_url: 'http://api.neopayout.com/prod'
};

const uatConfig = {
    api_url: 'http://api.neopayout.com/uat'
};

const devConfig = {
    api_url: 'http://localhost:3003/apiv1'
};

// return configurations based on the env
let config = null;
if(process.env.REACT_APP_ENV === 'prod')
    config = prodConfig;
if(process.env.REACT_APP_ENV === 'uat')
    config = uatConfig;
if(process.env.REACT_APP_ENV === 'dev')
    config = devConfig;

export default config;