import React from 'react';
import ShopeListPage from './ShopList';
import AddShopPage from './AddShop';
import ShopDetailsPage from './ShopDetails';
import ShopImagesPage from './ShopImages';
import ShopDashboardPage from './ShopDashboard';
import ShopSectionsPage from './ShopSectionsPage';
import DashBoardViewComponent from '../../components/DashBoardView';
import { BasicBreadcrumbs } from '../../components/BreadCrumbs';
import SideBarComponent from '../../components/SideBar';
import { getQueryValue, getData, setPayload, getPayload } from '../../helper';

function loadScreen(siteID = null) {
    let screenType = getQueryValue('screen');

    try {
        if(screenType === 'list') return <ShopeListPage/>;
        if(screenType === 'addnew') return <AddShopPage />;
        if(screenType === 'details') return <ShopDetailsPage site_id={siteID}/>;
        if(screenType === 'images') return <ShopImagesPage site_id={siteID}/>;
        if(screenType === 'sections') return <ShopSectionsPage site_id={siteID}/>;
        if(screenType === 'dashboard') return <ShopDashboardPage site_id={siteID}/>;
        return <ShopeListPage/>;
    } catch(err) {
        throw new Error(err.message);
    }
}

async function loadSiteData(siteID) {
    try {
        const sitePayload = getPayload('site');
        if(!sitePayload || !sitePayload[siteID]) {
            const url = `/sites/${siteID}`;
            const siteData = await getData(url);
            if(siteData && siteData.data && siteData.data.site) {
                const site = { 
                    [siteID] : siteData.data.site 
                };
                setPayload(site, 'site');
                window.location.reload();
            }
        }
    } catch(err) {
        throw new Error(err.message);
    }
}

function pageOptions(siteID) {
    try {

        let options = {
            list: {
                label: "Available Shops",
                link: "/shop?screen=list"
            },
            addnew: {
                label: "Add New Shop",
                link: "/shop?screen=addnew"
            }
        };

        let screenType = getQueryValue('screen');
        if(screenType && siteID) {

            // site options
            options = {
                dashboard: {
                    label: "Dashboard",
                    link: `/shop?screen=dashboard&site_id=${siteID}`
                },
                details: {
                    label: "Details",
                    link: `/shop?screen=details&site_id=${siteID}`
                },
                sections: {
                    label: "Sections",
                    link: `/shop?screen=sections&site_id=${siteID}`
                }
            }
        }

        return options;
    } catch(err) {
        throw new Error(err.message);
    }
}

function pageBreadCrumbs(siteID) {
    try {

        let breadCrumbs = {}; 

        let screenType = getQueryValue('screen');
        if(screenType && siteID) {

            // load site Data
            let sitePayload = getPayload('site');
            if(sitePayload && sitePayload[siteID]) {
                sitePayload = sitePayload[siteID];

                // breadCrumbs options
                breadCrumbs = {
                    shops: {
                        label: 'Shops',
                        link: '/shop'
                    },
                    site: {
                        label: sitePayload.site_name
                    }
                }
            }
        }

        return breadCrumbs;
    } catch(err) {
        throw new Error(err.message);
    }
}

function pageContent() {
    try {
        const siteID = getQueryValue('site_id');

        // load site data
        loadSiteData(siteID);

        // page options
        const sideBarOptions = pageOptions(siteID);
        const breadCrumbOptions = pageBreadCrumbs(siteID);
        
        // breadcrumbs
        const breadCrumbs = siteID ? <BasicBreadcrumbs breadCrumbs={breadCrumbOptions} /> : '';
        return (
            <>
                {breadCrumbs}
                <div id="wrapper">
                    <SideBarComponent options={sideBarOptions}/>
                    <div id="content-wrapper" className="d-flex flex-column">
                        {loadScreen(siteID)}
                    </div>
                </div>
            </>
        )
    } catch(err) {
        throw new Error(err.message);
    }
}

function ShopPage() {

    return (
        <>
            <section id='dashboard-page'>
                <DashBoardViewComponent title='Shops' content={pageContent()}/>
            </section>
        </>
    );
}

export default ShopPage;