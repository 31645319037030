import React from 'react';
import { postData, setPayload } from '../../helper';
import { ErrorToastComponent as ErrorToast } from '../../components/Toasts';

class LoginForm extends React.Component {
    constructor(props) {
        super();
        this.state = {
            email: '',
            password: '',
            err_message: null
        }
        this.submitHandler = this.submitHandler.bind(this);
    }

    componentDidMount() {

    }

    onChangeHandler = (e) => {
        try {
            this.setState({
                [e.target.name]:e.target.value,
                err_message: ''
            });
        } catch(err) {
            throw new Error(err.message);
        }
    }

    async submitHandler(e) {
        try {
            e.preventDefault();

            const self = this;
            if(self.state.email.length === 0 || self.state.password.length === 0) return; 

            const payload = {
                email_id: self.state.email,
                password: self.state.password
            };
            const response = await postData('/auth/login', payload);

            if(response && response.error && response.error.message)
                this.setState({err_message: response.error.message});
            else {
                if(response.data) {
                    const responseData = response.data;
                    if(responseData.user && responseData.user.info)
                        setPayload(responseData.user.info, 'user');
                    if(responseData.user && responseData.user.access_token) 
                        setPayload(responseData.user.access_token, 'access_token');
                }
                
                window.location.href='/dashboard';
                return;
            }

        } catch(err) {
            throw new Error(err.message);
        }
    }

    render() {
        return (
            <>
                <form className="user">
                    <div className="form-group">
                        <input type="email" className="form-control form-control-user"
                            id="inputEmail" aria-describedby="emailHelp"
                            placeholder="Enter Email Address..." value={this.state.email_id} 
                            name='email' onChange={this.onChangeHandler}/>
                    </div>
                    <div className="form-group">
                        <input type="password" className="form-control form-control-user"
                            id="exampleInputPassword" placeholder="Password" value={this.state.password}
                            name='password' onChange={this.onChangeHandler}/>
                    </div>
                    <ErrorToast message={this.state.err_message}/>
                    <button type="button" className="btn btn-primary btn-user btn-block" onClick={this.submitHandler}>
                        Login
                    </button>
                </form>
            </>
        );
    }

}

export default LoginForm;