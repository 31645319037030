import React from 'react';
import DashBoardViewComponent from '../../components/DashBoardView';

function OrganisationPage() {
    return (
        <>
            <DashBoardViewComponent title='Companies' content='hello hi boliye!'/>
        </>
    );
}

export default OrganisationPage;