import React from 'react';
import { SuccessToastComponent as SuccessToast } from '../../components/Toasts';
import { loadWarehousesOptions } from '../Warehouse/Helper';
import { postData } from '../../helper';

class TagWarehouseForm extends React.Component {
    constructor(props) {
        super();
        this.state = {
            base_count: 1,
            show_success: false,
            comsumed_quantity: 0,
            tagged_warehouses: [{
                warehouse_id: "",
                quantity: ""
            }],
            warehouse_options: [],
            tagged_options: []
        };
        this.renderForm = this.renderForm.bind(this);
        this.addField = this.addField.bind(this);
        this.removeField = this.removeField.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        try {

            const self = this;

            const availAbleWarehouses = await loadWarehousesOptions();
            const selectOptions = [];
            for(const value in availAbleWarehouses) {
                const label = availAbleWarehouses[value];
                selectOptions.push({
                    value: value, 
                    label: label 
                });
            }

            // if the tagged warehouses is not present
            let consumedQuantity = 0;
            const warehouses = [];
            if(self.props.tagged_warehouses) {
                for(const warehouse of self.props.tagged_warehouses) {
                    if(warehouse) {
                        consumedQuantity += parseInt(warehouse.quantity);
                        warehouses.push({
                            warehouse_id: warehouse.warehouse_id,
                            quantity: warehouse.quantity
                        });
                    }
                }
            }

            self.setState({
                base_count: warehouses.length,
                consumed_quantity: consumedQuantity,
                tagged_warehouses: warehouses,
                warehouse_options: selectOptions.length > 0 ? selectOptions : self.state.warehouse_options
            });

        } catch(err) {
            throw new Error(err.message);
        }
    }

    renderForm() {
        try {
            const baseCount = this.state.base_count;
            const fields = [];
            for(let i=0; i<=baseCount; i++) {
                const field = this.formFields(i);
                fields.push(field);
            }
            return fields;
        } catch(err) {
            throw new Error(err.message);
        }
    }

    formFields(index) {
        try {
            
            const warehouseOptions = this.state.warehouse_options;
            const taggedWarehouses = this.state.tagged_warehouses;
            const currentField = taggedWarehouses[index];

            if(typeof warehouseOptions === 'undefined' || warehouseOptions.length === 0) return "";

            const warehouseNameField = `warehouse_id-${index}`;
            const warehouseQuantityField = `quantity-${index}`;

            return (
                <div className="container margin-top-15" key={index}>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="form-group">
                                <div className="form-group-required">
                                    <label className="control-label">Warehouse Name</label>
                                </div>
                                <select className="form-control form-control-user"
                                    id={warehouseNameField} 
                                    name={warehouseNameField} 
                                    aria-describedby="selecthelp"
                                    placeholder='Warehouse Name' 
                                    value={currentField && currentField.warehouse_id ? currentField.warehouse_id : 0}
                                    onChange={this.handleOnChange}>
                                    {warehouseOptions.map(function(key, index) {
                                        const label = warehouseOptions[index].label;
                                        const value = warehouseOptions[index].value;
                                        return <option key={value} value={value}>{label}</option> 
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <div className="form-group-required">
                                    <label className="control-label">Quantity</label>
                                </div>
                                <input
                                id={warehouseQuantityField}
                                name={warehouseQuantityField}
                                aria-describedby="textHelp" 
                                type="number" 
                                className="form-control form-control-user"
                                value={currentField && currentField.quantity ? currentField.quantity : 0} 
                                onChange={this.handleOnChange}
                                placeholder="quantity" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group margin-top-10">
                                <div>
                                    <label className="control-label"></label>
                                </div>
                                {index === this.state.base_count ?
                                    <button type="button" className="btn btn-success btn-icon-split" onClick={() => this.addField(index)}>
                                        <span className="icon text-white-50">
                                            <i className="fas fa-plus"></i>
                                        </span>
                                    </button>
                                    : ''
                                }
                                {index !== this.state.base_count ?
                                    <button type="button" className="btn btn-danger btn-icon-split" onClick={() => this.removeField(index)}>
                                        <span className="icon text-white-50">
                                            <i className="fas fa-trash-alt"></i>
                                        </span>
                                    </button>
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        } catch(err) {
            throw new Error(err.message);
        }
    }

    handleOnChange(e) {
        try {

            let fieldValue = e.target.value;
            const fieldName = e.target.name;
            const fieldProps = fieldName.split('-');
            const fieldType = fieldProps[0];
            const fieldIndex = fieldProps[1];

            const taggedWarehouses = this.state.tagged_warehouses;

            // if the field type is quantity
            if(fieldType === 'quantity') {
                const calculatedValue = this.calcQuantity(taggedWarehouses, fieldValue);
                fieldValue = calculatedValue;
            }
            fieldValue = isNaN(fieldValue) ? fieldValue : parseInt(fieldValue);

            // update the warehouse value
            if(taggedWarehouses.length > 0 && typeof taggedWarehouses[fieldIndex] !== 'undefined') {
                taggedWarehouses[fieldIndex][fieldType] = fieldValue;
            } else {
                taggedWarehouses[fieldIndex] = {
                    [fieldType]: fieldValue
                };
            }

            // udpate tagged warehouse
            this.setState({tagged_warehouses: taggedWarehouses});

        } catch(err) {
            throw new Error(err.message);
        }
    }
    
    calcQuantity = (taggedWarehouses, currentValue) => {
        try {

            currentValue = parseInt(currentValue);
            const availQuantity = this.props.quantity;
            let sum = 0;
            for(const warehouse of taggedWarehouses) {
                const quantity = warehouse.quantity;
                sum += quantity;
            }

            if(sum > availQuantity)
                currentValue = Math.abs(sum - availQuantity - currentValue);

            return currentValue;
        
        } catch(err) {
            throw new Error(err.message);
        }
    }

    addField(index) {
        try {
            const self = this;
            const currentFields = self.state.tagged_warehouses[index];
            console.log(currentFields);
            // /if(currentFields && !currentFields.quantity && !currentFields.warehouse_id) return ;

            const baseCount = self.state.base_count + 1;
            self.setState({
                base_count: baseCount
            });
        } catch(err) {
            throw new Error(err.message);
        }
    }

    removeField(index) {
        try {

            // console.log(index);
            const self = this;
            const baseCount = self.state.base_count - 1;

            const taggedWarehouses = self.state.tagged_warehouses;
            taggedWarehouses.splice(index, 1);

            self.setState({
                base_count: baseCount,
                tagged_warehouses: taggedWarehouses 
            });

        } catch(err) {
            throw new Error(err.message);
        }
    }

    async handleSubmit() {
        try {

            const self = this;
            const payload = {
                inventory_id: self.props.inventory_id,
                tagged_warehouses: self.state.tagged_warehouses
            };

            const response = await postData('/inventory/transfer', payload);
            if(response && response.data && response.data.tagged_warehouses) {
                self.setState({
                    show_success: true,
                    tagged_warehouses: response.data.tagged_warehouses
                });
            }

            setTimeout(() => {
                self.setState({show_success: false});
            },5000);

            window.location.reload();

        } catch(err) {
            throw new Error(err.message);
        }
    }

    render() {
        return (
            <>
                <p className='text-sm mb-0'>Allocated Quantity: <span className='text-gray-900'>{this.state.consumed_quantity}</span></p>
               {this.renderForm()} 
               <div className="container margin-top-15">
                    <div className="row">
                        <div className="col-md-5">
                            <button type="button" className="btn btn-success btn-icon-split" onClick={this.handleSubmit}>
                                <span className="icon text-white-50">
                                    <i className="fas fa-exchange-alt"></i>
                                </span>
                                <span className="text">Transfer</span>
                            </button>
                        </div>
                    </div>
                </div>
                {this.state.show_success ? <SuccessToast message='Item has been transferred!'/> : ''}
            </>
        )
    }

}

export default TagWarehouseForm;