import React from 'react';
import LineChartComponent from '../../components/Charts/Line'
import { ErrorToastComponent as ErrorToast } from '../../components/Toasts';

class ShopDashboardPage extends React.Component {
    constructor(props) {
        super();
        this.state = {}
    }

    async componentDidMount() {
        try {

        } catch(err) {
            throw new Error(err.message);
        }
    }

    pageContent() {
        try {
            return (
                <>  
                    <div className='container'>
                        <div className='row'>
                            <div className='col-xl-8 col-lg-7'>
                                <LineChartComponent label='Earning Summary'/>
                            </div>
                            <div className='col-xl-4 col-lg-5'>
                                <LineChartComponent label='Total Earning'/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xl-8 col-lg-7'>
                                <LineChartComponent label='Order Summary'/>
                            </div>
                            <div className='col-xl-4 col-lg-5'>
                                <LineChartComponent label='Total Orders'/>
                            </div>
                        </div>
                    </div>
                </>
            )
        } catch(err) {
            throw new Error(err.message);
        }
    }

    render() {
        return (
            <>  
                {this.pageContent()}
                <ErrorToast message={this.state.err_message}/>
            </>
        );
    }
}

export default ShopDashboardPage;