import React from 'react';
import AddImageForm from './AddImageForm';

function ShopImagesPage(props) {
    return (
        <>  
            <AddImageForm site_id={props.site_id}/>
        </>
    );
}

export default ShopImagesPage;