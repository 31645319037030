import { getData } from '../../helper';

export async function loadCategoryOptions(type) {
    try {

        const categories = await loadCategories(type);
        const options = {};
        for(let data of categories) {
            options[data.category.category_id] = data.category.category_name;
        }
        return options;

    } catch(err) {
        return [];
    }
}

export async function loadCategories(type) {
    try {

        const payload = await getData(`/categories/${type}`);
        if(payload && payload.data && payload.data.categories)
            return payload.data.categories;
        return [];

    } catch(err) {
        throw new Error(err.message);
    }
} 