import React from 'react';
import FormControl from '../../components/FormControl';
import { loadInventoryOptions, loadItemInfo } from './Helper';
import TagWarehouseForm from './TagWarehouse';
import { ErrorToastComponent as ErrorToast } from '../../components/Toasts';
import { BasicCard } from '../../components/Card';
import { getQueryValue } from '../../helper';

class TransferInventoryPage extends React.Component {
    constructor(props) {
        super();
        this.inventorySelectFormsubmitHandler = this.inventorySelectFormsubmitHandler.bind(this);
        this.inventoryInformation = this.inventoryInformation.bind(this);
        this.pageContent = this.pageContent.bind(this);
        this.inventoryID = getQueryValue('inventory_id') ?? null;
        const self = this;
        this.state = {
            err_message: null,
            page_label: 'Transfer Item',
            inventory_id: self.inventoryID,
            item_info: {},
            inventorySelectForm: {
                onSubmit: self.inventorySelectFormsubmitHandler
            },
            fields: { 
                inventory_id: {
                    type: 'select-search',
                    placeholder: 'Item',
                    required: true,
                    options: {},
                    onChange: self.inventoryOnChangeHandler
                },
                create_button: {
                    type: 'button',
                    label: 'Next',
                    btn_type: 'submit'
                }
            }
        };
    }

    async componentDidMount() {
        try {

            const self = this;
            const currentFields = self.state.fields;
            currentFields.inventory_id.options = await loadInventoryOptions();
            currentFields.inventory_id.value = self.state.inventory_id;

            let itemInfo = {};
            if(self.inventoryID) 
                itemInfo = await loadItemInfo(self.inventoryID);

            self.setState({
                fields: currentFields,
                item_info: itemInfo,
                page_label: itemInfo.inventory_name ? `${self.state.page_label} (${itemInfo.inventory_name})` : self.state.page_label
            });
           
        } catch(err) {
            throw new Error(err.message);
        }
    }

    inventoryOnChangeHandler = (e) => {
        try {
            const fields = this.state.fields;
            fields.inventory_id.value = e.value;
            this.setState({fields: fields});
        } catch(err) {
            throw new Error(err.message);
        }
    }

    async inventorySelectFormsubmitHandler(payload) {
        try {

            const inventoryID = payload.inventory_id ?? null;
            window.location.href = `/inventory?screen=transfer&inventory_id=${inventoryID}`;
            return;

        } catch(err) {
            return new Error(err.message);
        }
    }

    inventoryInformation() {
        try {
            if(!this.state.inventory_id)
                return <FormControl fields={this.state.fields} config={this.state.inventorySelectForm}/>
            else
                return (
                    <>
                        <p className='text-lg mb-0'>Available Quantity: <span className='text-gray-900'>{this.state.item_info.quantity}</span> </p>
                        <TagWarehouseForm key={this.state.item_info.quantity} 
                        inventory_id={this.state.inventory_id}
                        quantity={this.state.item_info.quantity} 
                        tagged_warehouses={this.state.item_info.tagged_warehouses}/>
                    </>
                );
        } catch(err) {
            throw new Error(err.message);
        }
    }

    pageContent() {
        try {
            return (
                <>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="card o-hidden border-0">
                                    <div className="card-body p-0">
                                        <div className="p-5">
                                            {this.inventoryInformation()}
                                            <ErrorToast message={this.state.err_message}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        } catch(err) {
            throw new Error(err.message);
        }
    }

    render() {
        return (
            <>
                <div className='container'>
                    <BasicCard title={this.state.page_label} content={this.pageContent(this.props)}/>
                </div>
            </>
        )
    }
}


export default TransferInventoryPage;