import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export function SuccessToastComponent(props) {
    if(!props.message) return '';
    { toast(props.message ?? '') }
    return (
        <>
            <ToastContainer />
        </>
    )
}

export function ErrorToastComponent(props) {
    if(!props.message) return '';
    { toast.error(props.message ?? '') }
    return (
        <>
            <ToastContainer />
        </>
    )
}

export function WarningToastComponent(props) {
    if(!props.message) return '';
    { toast(props.message ?? '') }
    return (
        <>
            <ToastContainer />
        </>
    )
}