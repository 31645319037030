import { getData } from '../../helper';

export async function loadInventoryOptions() {
    try {

        const inventory = await loadInventories();
        const options = {};
        for(let data of inventory) {
            if(data.item.inventory_name) 
                options[data.item.inventory_id] = data.item.inventory_name;
        }
        return options;

    } catch(err) {
        return [];
    }
}

export async function loadInventories() {
    try {

        const payload = await getData(`/inventory`);
        if(payload && payload.data && payload.data.inventory)
            return payload.data.inventory;
        return [];

    } catch(err) {
        throw new Error(err.message);
    }
} 

export async function loadItemInfo(itemID) {
    try {

        const payload = await getData(`/inventory/${itemID}`);
        if(payload && payload.data && payload.data.item)
            return payload.data.item;
        return {};

    } catch(err) {
        throw new Error(err.message);
    }
} 