import React from 'react';
import FormControl from '../../components/FormControl';
import { ErrorToastComponent as ErrorToast } from '../../components/Toasts';
import { postFormData, getData, setPayload, primaryCompany, getPayload } from '../../helper';

class UpdateUserKYCForm extends React.Component {
    constructor(props) {
        super();
        const userData = getPayload('user');
        this.submitHandler = this.submitHandler.bind(this);
        const self = this;
        this.state = {
            err_message: null,
            form: {
                onSubmit: self.submitHandler
            },
            fields: { 
                pan_card_front: {
                    type: 'file',
                    placeholder: 'Pancard (Front Image)',
                    value: '',
                    required: true,
                    onChange: self.onChangeHandler
                },
                pan_card_back: {
                    type: 'file',
                    placeholder: 'Pancard (Back Image)',
                    value: '',
                    required: true,
                    onChange: self.onChangeHandler
                },
                update_button: {
                    type: 'button',
                    label: 'Update KYC',
                    btn_type: 'submit'
                }
            }
        };
    }

    componentDidMount() {
        try {
            const companyData = primaryCompany();
            if(companyData && companyData.company) {
                if(companyData.company.active === 1)
                    window.location.href = '/dashboard';
                else
                    window.location.href = `/company-verify?company_id=${companyData.company.company_id}`;
                return;
            }
        } catch(err) {
            throw new Error(err.message);
        }
    }

    onChangeHandler = (e) => {
        try {
            const fields = this.state.fields;
            fields[e.target.name].value = e.target.files[0];
            this.setState({fields: fields});
        } catch(err) {
            throw new Error(err.message);
        }
    }

    async submitHandler(payload) {
        try {

            const formdata = new FormData();
            formdata.append("pan_card_front", payload.pan_card_front, payload.pan_card_front.name);
            formdata.append("pan_card_back", payload.pan_card_back, payload.pan_card_back.name);

            const response = await postFormData('/users/upload', formdata, 'PUT');
            if(response.error && response.error.message) {
                this.setState({err_message: response.error.message});
            } else {
                if(response && response.data && response.data.file_uploaded) {
                    if(!response.data.file_uploaded.pan_card_back) {
                        this.setState({err_message: 'pan card back image not uploaded'});
                        return;
                    }
                    else if(!response.data.file_uploaded.pan_card_front) {
                        this.setState({err_message: 'pan card front image not uploaded'});
                        return;
                    } else {
                        const userRes = await getData('/users');
                        if(userRes.data && userRes.data.user && userRes.data.user.info) {
                            setPayload(userRes.data.user.info, 'user');
                            window.location.href = '/company-add';
                        }
                    }
                }
            }

        } catch(err) {
            return new Error(err.message);
        }
    }

    render() {
        return (
            <>
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-4">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="p-5">
                                    <FormControl fields={this.state.fields} config={this.state.form}/>
                                    <ErrorToast message={this.state.err_message}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default UpdateUserKYCForm;