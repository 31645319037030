import React from 'react';
import ToggleButton from 'react-toggle-button'
import { setPayload, getPayload, urlParam } from '../helper';

class OptionBarComponent extends React.Component {
    constructor(props) {
        super();
        const liveModeValue = getPayload('live_mode_value') ?? true;
        this.state = {
            check_counter: 0,
            show_toggle: false,
            live_mode: liveModeValue
        };
        this.toggleTestMode = this.toggleTestMode.bind(this);
        this.toggleRender = this.toggleRender.bind(this);
    }

    componentDidMount() {
        try {
            
            // get the current url
            const currentURL = urlParam(1);

            // show toggle only when the URL is not restricted urls
            if(!['shop'].includes(currentURL)) {
                this.setState({
                    show_toggle: true
                });
            }

        } catch(err) {
            throw new Error(err.message);
        }
    }

    toggleTestMode(value) {
        try {

            // store the test mode value
            const liveModeValue = !value;
            this.setState({live_mode: liveModeValue});
            setPayload(liveModeValue, 'live_mode_value');

        } catch(err) {
            throw new Error(err.message);
        }
    }

    toggleRender() {
        try {
            const self = this;
            const tokenData = getPayload('token_data');
            if(self.state.show_toggle && tokenData && tokenData.length > 0) {
                return (
                    <>
                        <span className='margin-right-10'>Production Mode</span>
                        <ToggleButton
                        value={ self.state.live_mode || false }
                        onToggle={(value) => self.toggleTestMode(value)} />
                    </>
                );
            }
            return '';
        } catch(err) {
            console.log(err);
            throw new Error(err.message);
        }
    }

    render() {
        const self = this;
        let navBarClass = 'navbar navbar-expand-lg navbar-light navbar-custom ';
        if(self.state.live_mode || !self.state.show_toggle)
            navBarClass += 'live-mode-bottom';
        else
            navBarClass += 'test-mode-bottom';

        return (
            <>
                <nav className={navBarClass}>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item active">
                                <a className="nav-link" href="/dashboard">Home</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" href="/shop">Shop</a>
                            </li> */}
                            <li className="nav-item">
                                <a className="nav-link" href="/inventory?screen=list">Inventory</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" href="#">Orders</a>
                            </li> */}
                            {/* <li className="nav-item">
                                <a className="nav-link" href="/developer">Developer</a>
                            </li> */}
                        </ul>
                    </div>
                    {/* {this.toggleRender()} */}
                </nav>
            </>
        );
    }
}

export default OptionBarComponent;