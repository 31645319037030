import React from 'react';
import { Line } from 'react-chartjs-2';

export default function LineChartComponent(props) {

    const data = {
        labels: ['1', '2', '3', '4', '5', '6'],
        datasets: [
            {
                label: props.label,
                data: [12, 19, 3, 5, 2, 3],
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)'
            },
        ],
    };
      
    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    return (
        <>
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <h6 class="m-0 font-weight-bold text-primary">{props.label || ''}</h6>
                </div>
                <div className="card-body">
                    <div className="chart-area">
                        <Line data={data} options={options} className='chart-style'/>
                    </div>
                </div>
            </div>
        </>
    )
}