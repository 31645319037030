import React from 'react';
import DashBoardViewComponent from '../../components/DashBoardView';
import VerifyCompanyForm from '../Organisation/VerifyForm';

function pageContent() {
    try {
        return (
            <>
                <VerifyCompanyForm/>
            </>
        );
    } catch(err) {
        return '';
    }
}

function OrganisationAddPage() {
    return (
        <>
            <DashBoardViewComponent title='Almost There! Verify your Business Email Account' content={pageContent()}/>
        </>
    );
}

export default OrganisationAddPage;