import { getData } from '../../helper';

export async function loadWarehousesOptions() {
    try {

        const warehouses = await loadWarehouses();
        const options = {};
        for(let data of warehouses) {
            options[data.warehouse.warehouse_id] = data.warehouse.warehouse_name;
        }
        return options;

    } catch(err) {
        return [];
    }
}

export async function loadWarehouses() {
    try {

        const payload = await getData(`/warehouses`);
        if(payload && payload.data && payload.data.warehouses)
            return payload.data.warehouses;
        return [];

    } catch(err) {
        throw new Error(err.message);
    }
} 