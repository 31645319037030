import { getData } from '../../helper';

export async function loadUnitOptions(type) {
    try {

        const units = await loadUnits(type);
        const options = {};
        for(let data of units) {
            options[data.unit.unit_id] = data.unit.unit_name;
        }
        return options;

    } catch(err) {
        return [];
    }
}

export async function loadUnits(type) {
    try {

        const payload = await getData(`/units/${type}`);
        if(payload && payload.data && payload.data.units)
            return payload.data.units;
        return [];

    } catch(err) {
        throw new Error(err.message);
    }
} 