import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import HomePage from './pages/Home';
import LoginPage from './pages/Login';
import RegisterPage from './pages/Register';
import ForgotPasswordPage from './pages/ForgotPassword';
import ConfirmOtpPage from './pages/ConfirmOtp';
import DashboardPage from './pages/Dashboard';

// import UserPage from './pages/User';
import UserKYCPage from './pages/User/KYC';
import UserUpdatePage from './pages/User/Update';

import OrganisationPage from './pages/Organisation';
import OrganisationAddPage from './pages/Organisation/Add';
import OrganisationVerifyPage from './pages/Organisation/Verify';

import DeveloperPage from './pages/Developer';
import ShopPage from './pages/Shop';
import InventoryPage from './pages/Inventory';

function App() {
    return (
      <Router>
        <Switch>

          {/*  */}
          <Route exact path='/developer' children={<DeveloperPage/>}/>
          <Route exact path='/shop' children={<ShopPage/>}/>
          <Route exact path='/inventory' children={<InventoryPage/>}/>

          <Route exact path='/company-verify' children={<OrganisationVerifyPage/>}/>
          <Route exact path='/company-add' children={<OrganisationAddPage/>}/>
          <Route exact path='/companies' children={<OrganisationPage/>}/>
          <Route exact path='/user-kyc' children={<UserKYCPage/>}/>
          <Route exact path='/user-update' children={<UserUpdatePage/>}/>
          <Route exact path='/dashboard' children={<DashboardPage/>}/>
          <Route exact path='/login' children={<LoginPage/>}/>
          <Route exact path='/register' children={<RegisterPage/>}/>
          <Route exact path='/forgot-password' children={<ForgotPasswordPage/>}/>
          <Route exact path='/confirm-otp' children={<ConfirmOtpPage/>}/>
          <Route exact path='/' children={<HomePage/>}/>
          {/* <Route children={<NotFoundPage />} />            */}
        </Switch>
      </Router>
    );
}
  

export default App;