import React from 'react';
import DashBoardViewComponent from '../../components/DashBoardView';
import UpdateUserForm from '../User/UpdateForm';

function pageContent() {
    try {
        return (
            <>
                <UpdateUserForm/>
            </>
        );
    } catch(err) {
        return '';
    }
}

function UserUpdatePage() {
    return (
        <>
            <DashBoardViewComponent title='Update Personal Information' content={pageContent()}/>
        </>
    );
}

export default UserUpdatePage;