import React from 'react';
import DashBoardViewComponent from '../../components/DashBoardView';

function DashboardPage() {
    return (
        <>
            <section id='dashboard-page'>
                <DashBoardViewComponent title='Dashboard' content='hello hi boliye!'/>
            </section>
        </>
    )
}

export default DashboardPage;