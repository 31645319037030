import React from 'react';
import { postData, setPayload } from '../../helper';
import { ErrorToastComponent as ErrorToast } from '../../components/Toasts';

class RegisterForm extends React.Component {
    constructor(props) {
        super();
        this.state = {
            email: '',
            first_name: 'Vibhav',
            last_name: 'Yadav',
            password: 'Vibhav@1991',
            confirm_password: 'Vibhav@1992',
            err_message: null
        };
        this.submitHandler = this.submitHandler.bind(this);
    }

    onChangeHandler = (e) => {
        try {
            this.setState({
                [e.target.name]:e.target.value,
                err_message: ''
            })
        } catch(err) {
            throw new Error(err.message);
        }
    }

    async submitHandler(e) {
        try {

            const self = this;
            if(self.state.email.length === 0 || self.state.password.length === 0) return; 

            if(self.state.password !== self.state.confirm_password) {
                self.setState({err_message: 'password and confirm password does not match'});
                return;
            }

            const response = await postData('/auth/register',{
                email_id: self.state.email,
                password: self.state.password,
                first_name: self.state.first_name,
                last_name: self.state.last_name
            });

            if(response.error && response.error.message)
                self.setState({err_message: response.error.message});
            else {

                if(response.data) {
                    const responseData = response.data;
                    if(responseData.user && responseData.user.info)
                        setPayload(responseData.user.info, 'user');
                    if(responseData.user && responseData.user.access_token) 
                        setPayload(responseData.user.access_token, 'access_token');
                }
                
                window.location.href='/confirm-otp';
                return;
            }

        } catch(err) {  
            throw new Error(err.message);
        }
    }

    renderErrorToast = () => {
        try {
            if(!this.state.err_message) return '';
            return <ErrorToast message={this.state.err_message}/>
        } catch(err) {
            return err.message;
        }
    }

    render() {
        return (
            <>
                <form className="user">
                    <div className="form-group row">
                        <div className="col-sm-6 mb-3 mb-sm-0">
                            <input type="text" className="form-control form-control-user" id="FirstName"
                                placeholder="First Name" name="first_name" 
                                onChange={this.onChangeHandler} value={this.state.first_name}/>
                        </div>
                        <div className="col-sm-6">
                            <input type="text" className="form-control form-control-user" id="LastName"
                                placeholder="Last Name" name="last_name" 
                                onChange={this.onChangeHandler} value={this.state.last_name}/>
                        </div>
                    </div>
                    <div className="form-group">
                        <input type="email" className="form-control form-control-user" id="InputEmail"
                            placeholder="Email Address" name="email" 
                            onChange={this.onChangeHandler} value={this.state.email}/>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-6 mb-3 mb-sm-0">
                            <input type="password" className="form-control form-control-user"
                                id="InputPassword" name="password" placeholder="Password" 
                                onChange={this.onChangeHandler} value={this.state.password}/>
                        </div>
                        <div className="col-sm-6">
                            <input type="password" className="form-control form-control-user"
                                id="InputConfirmPassword" name='confirm_password' placeholder="Confirm Password" 
                                onChange={this.onChangeHandler} value={this.state.confirm_password}/>
                        </div>
                    </div>
                    {this.renderErrorToast()}
                    <button type="button" className="btn btn-primary btn-user btn-block" onClick={this.submitHandler}>
                        Register Account
                    </button>
                </form>
            </>
        );
    }
}

export default RegisterForm;