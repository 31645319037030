import React from 'react';
import ContactDetailsForm from './ContactDetailsForm';
import PickupDetailsForm from './PickupDetailsForm';
import AddImageForm from './AddImageForm';
import { BasicCard } from '../../components/Card';

function pageContent(props) {
    try {
        return (
            <>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-3">
                        <div className='col-lg-12'>
                            <AddImageForm site_id={props.siteID}/>
                        </div>
                        <div className='col-lg-12 my-5'>
                            <ContactDetailsForm site_id={props.site_id}/>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-9">
                        <PickupDetailsForm site_id={props.site_id}/>
                    </div>
                </div>
            </>
        )
    } catch(err) {
        throw new Error(err.message);
    }
}

function ShopDetailsPage(props) {
    return (
        <>  
            <div className="container">
                <BasicCard title='Shop Details' content={pageContent(props)}/>
            </div>
        </>
    );
}

export default ShopDetailsPage;