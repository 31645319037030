import React from 'react';
import { 
    EmailField,
    TextField,
    PasswordField,
    SelectField,
    SelectSelectField,
    CreatableSelectField,
    CountryField,
    StateField,
    CityField,
    FileField,
    NumberField,
    Button 
} from './FormField';

class FormControl extends React.Component {
    constructor(props) {
        super();
        this.state = {
            fields: {}
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        try {
            const self = this;
            self.setState({ fields: self.props.fields });
        } catch(err) {
            return;
        }
    }

    fieldType = (field, fieldProp) => {
        try {
            switch(fieldProp.type) {
                case 'text':
                    return <TextField key={field} field={field} config={fieldProp}/>;
                case 'email':
                    return <EmailField key={field} field={field} config={fieldProp}/>;
                case 'password':
                    return <PasswordField key={field} field={field} config={fieldProp}/>;
                case 'select':
                    return <SelectField key={field} field={field} config={fieldProp}/>;
                case 'select-search':
                    return <SelectSelectField key={field} field={field} config={fieldProp}/>;
                case 'createable-select':
                    return <CreatableSelectField key={field} field={field} config={fieldProp}/>;
                case 'country':
                    return <CountryField key={field} field={field} config={fieldProp}/>;
                case 'state':
                    return <StateField key={field} field={field} config={fieldProp}/>;
                case 'city':
                    return <CityField key={field} field={field} config={fieldProp}/>;
                case 'number':
                    return <NumberField key={field} field={field} config={fieldProp}/>;
                case 'file':
                    return <FileField key={field} field={field} config={fieldProp}/>;
                case 'button':
                    return <Button key={field} field={field} config={fieldProp}/>;
                default:
                    return '';
            }
        } catch(err) {
            return err.message;
        }
    }

    renderFields = (fields) => {
        try {
            const self = this;
            return Object.keys(fields).map(function(key, index) {
                const field = fields[key];
                return self.fieldType(key, field);
            });
        } catch(err) {
            return err.message;
        }
    }

    handleSubmit(e) {
        try {
            e.preventDefault();
            const self = this;
            const fields = self.state.fields;

            // iterate through each field
            const payload = {};
            for(const key in fields) {
                const field = fields[key];
                if(field.required && field.value.length === 0) {
                    return;
                }
                payload[key] = fields[key].value;
            }

            // parent onsubmit function
            self.props.config.onSubmit(payload);

        } catch(err) {
            throw new Error(err.message);
        }
    }

    render() {
        return (
            <>
                <form className="factory-form" onSubmit={this.handleSubmit}>
                    {this.renderFields(this.props.fields)}
                </form>
            </>
        );
    }
}

export default FormControl;