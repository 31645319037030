import React from 'react';
import { postData, primaryCompany, setPayload, getPayload } from '../../helper';
import DashBoardViewComponent from '../../components/DashBoardView';
import { BasicDataTable } from '../../components/DataTable';
import { ErrorToastComponent as ErrorToast  } from '../../components/Toasts';
import { loadTokens } from './Helper';

class DeveloperPage extends React.Component {
    constructor(props) {
        super();
        const liveModeValue = getPayload('live_mode_value') ?? true;
        this.state = {
            live_mode: liveModeValue,
            err_message: null,
            datatable: {
                columns: [
                    {
                        label: 'Environment',
                        field: 'environment',
                        width: 50,
                    },
                    {
                        label: 'API Token',
                        field: 'token',
                    },
                    {
                        label: 'Action',
                        field: 'action',
                    }
                ],
                rows: []
            }
        }
        this.createNewToken = this.createNewToken.bind(this);
        this.deleteToken = this.deleteToken.bind(this);
    }

    async componentDidMount() {
        try {

            // load tokens
            await this.loadAvailableTokens();

        } catch(err) {
            throw new Error(err.message);
        }
    }

    async copyToken(tokenID) {
        try {

            // copy text to clipboard
            console.log(tokenID);
            navigator.clipboard.writeText(tokenID);
            alert('Token Copied!');
            
        } catch(err) {
            throw new Error(err.message);
        }
    }

    async deleteToken(environment) {
        try {

            const companyData = primaryCompany();
            const companyID = companyData.company.company_id ?? null;

            const apiPath = `/tokens/remove/${environment}`;
            const payload = {
                company_id: companyID
            };

            // cretea new token
            await postData(apiPath, payload, 'DELETE');
            await this.loadAvailableTokens();

        } catch(err) {
            throw new Error(err.message);
        }
    }

    loadActionableBtns(tokenRow) {
        try {
            return (
                <>
                    {/* <button className="btn btn-success btn-circle btn-sm" data-toggle="tooltip" title='Remove token' onClick={() => this.copyToken(tokenRow.token)}>
                        <i className="fas fa-trash"></i>
                    </button> */}
                    <button className="btn btn-danger btn-circle btn-sm" data-toggle="tooltip" title='Remove token' onClick={() => this.deleteToken(tokenRow.environment)}>
                        <i className="fas fa-trash"></i>
                    </button>
                </>
            );
        } catch(err) {
            throw new Error(err.message);
        }
    }

    async loadAvailableTokens() {
        try {

            const self = this;
            const companyData = primaryCompany();
            const companyID = companyData.company.company_id ?? null;
            
            const tokens = await loadTokens(companyID);;

            if(tokens) {

                const tokenRows = [];
                for(const tokenIndex in tokens) {
                    const tokenRow = tokens[tokenIndex];
                    tokenRow['action'] = self.loadActionableBtns(tokenRow);
                    tokenRows.push(tokenRow);
                }

                self.setState({
                    datatable: {
                        columns: self.state.datatable.columns,
                        rows: tokenRows
                    }
                });
                setPayload(tokens, 'token_data');
            }

        } catch(err) {
            throw new Error(err.message);
        }
    }

    renderTable() {
        try {
            let table = 'No tokens available';
            if(this.state.datatable.rows.length > 0) {
                table = <BasicDataTable label='Available Tokens' data={this.state.datatable} />;
            }

            return (
                
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Available Tokens</h6>
                        {this.addTokenButton()}
                    </div>
                    <div className="card-body">
                        {table}
                    </div>
                </div>
            );
        } catch(err) {
            throw new Error(err.message);
        }
    }

    async createNewToken() {
        try {

            let envType = 'prod';
            const liveModeValue = getPayload('live_mode_value') ?? true;
            if(!liveModeValue)
                envType = 'test';

            const companyData = primaryCompany();
            const companyID = companyData.company.company_id ?? null;

            const apiPath = `/tokens/register/${envType}`;
            const payload = {
                company_id: companyID
            };

            // cretea new token
            const res = await postData(apiPath, payload);
            if(res && res.error && res.error.message) {
                this.setState({
                    err_message: `${envType} : ${res.error.message}`
                });
            }
            await this.loadAvailableTokens();

        } catch(err) {
            throw new Error(err.message);
        }
    }

    addTokenButton() {
        try {
            let envType = 'production';
            if(!this.state.live_mode)
                envType = 'test';
            const tooltipText = `Add new ${envType} token`;
            return (
                <button className="btn btn-success btn-circle btn-sm add-token-btn" data-toggle="tooltip" title={tooltipText} onClick={this.createNewToken}>
                    <i className="far fa-plus-square"></i>
                </button>
            )
        } catch(err) {
            throw new Error(err.message);
        }
    }

    pageContent() {
        try {
            return (
                <>
                    {this.renderTable()}
                </>
            )
        } catch(err) {
            throw new Error(err.message);
        }
    }

    render() {
        return (
            <>
                <section id='dashboard-page'>
                    <DashBoardViewComponent title='Developer Options' content={this.pageContent()}/>
                </section>
                <ErrorToast message={this.state.err_message}/>
            </>
        );
    }
}

export default DeveloperPage;