import React from 'react';
import { getData } from '../../helper';
import { BasicDataTable } from '../../components/DataTable';
import { ErrorToastComponent as ErrorToast  } from '../../components/Toasts';

class ShopeListPage extends React.Component {
    constructor(props) {
        super();
        this.state = {
            datatable: {
                columns: [
                    {
                        label: 'Shop Name',
                        field: 'shop_name',
                        width: 150,
                    },
                    {
                        label: 'Domain',
                        field: 'domain',
                    },
                    {
                        label: 'Action',
                        field: 'site_action',
                    }
                ],
                rows: []
            }
        }
    }

    async componentDidMount() {
        try {

            // load sites
            await this.loadAvailableSites();

        } catch(err) {
            throw new Error(err.message);
        }
    }

    async loadAvailableSites() {
        try {

            const self = this;
            
            const apiPath = `/sites`;
            const response = await getData(apiPath);

            const siteRows = [];
            if(response && response.data && response.data.sites) {
                const sites = response.data.sites;
                for(const siteInfo of sites) {
                    const site = {
                        shop_name: siteInfo.site.site_name,
                        domain: siteInfo.site.meta_data.cname.name,
                        site_action: self.siteActions(siteInfo.site.site_id)
                    };
                    siteRows.push(site);
                }
            }
            self.setState({
                datatable: {
                    columns: self.state.datatable.columns,
                    rows: siteRows
                }
            });

        } catch(err) {
            throw new Error(err.message);
        }
    }

    redirectSitePage(siteID) {
        try {
            const path = `/shop?screen=dashboard&site_id=${siteID}`;
            window.location.href = path;
            return;
        } catch(err) {
            throw new Error(err.message);
        }
    }

    siteActions(siteID) {
        try {
            return (
                <>
                    <button className="btn btn-info btn-circle btn-sm" data-toggle="tooltip" title='Check Site Information' onClick={() => this.redirectSitePage(siteID)}>
                        <i className="fas fa-info-circle"></i>
                    </button>
                </>
            );
        } catch(err) {
            throw new Error(err.message);
        }
    }

    async createNewSite() {
        try {
            window.location.href = '/shop?screen=add-new';
            return;
        } catch(err) {
            throw new Error(err.message);
        }
    }

    addSiteButton() {
        try {
            return (
                <button className="btn btn-success btn-circle btn-sm add-token-btn" data-toggle="tooltip" title='Create new shop' onClick={this.createNewSite}>
                    <i className="far fa-plus-square"></i>
                </button>
            )
        } catch(err) {
            throw new Error(err.message);
        }
    }

    renderTable() {
        try {
            let table = 'No shops available';
            if(this.state.datatable.rows.length > 0) {
                console.log(this.state.datatable);
                table = <BasicDataTable label='Available Shops' data={this.state.datatable} />;
            }

            return (
                
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Available Shops</h6>
                        {this.addSiteButton()}
                    </div>
                    <div className="card-body">
                        {table}
                    </div>
                </div>
            );
        } catch(err) {
            throw new Error(err.message);
        }
    }

    pageContent() {
        try {
            return (
                <>
                    {this.renderTable()}
                </>
            )
        } catch(err) {
            throw new Error(err.message);
        }
    }

    render() {
        return (
            <>  
                <div class='container'>
                    {this.pageContent()}
                    <ErrorToast message={this.state.err_message}/>
                </div>
            </>
        );
    }
}

export default ShopeListPage;