import React from 'react';
import InventoryListPage from './InventoryList';
import AddInventoryPage from './AddInventory';
import TransferInventoryPage from './TransferInventory';
import UpdateStockPage from './UpdateStock';
import WarehouseListPage from '../Warehouse/WarehouseListing';
import AddWarehousePage from '../Warehouse/AddWarehouse';
import CategoryListPage from '../Category/CategoryListing';
import AddCategoryPage from '../Category/AddCategory';
import BrandListPage from '../Brand/BrandListing';
import AddBrandPage from '../Brand/AddBrand';
import UnitListPage from '../Unit/UnitListing';
import AddUnitPage from '../Unit/AddUnit';
import DashBoardViewComponent from '../../components/DashBoardView';
import { BasicBreadcrumbs } from '../../components/BreadCrumbs';
import SideBarComponent from '../../components/SideBar';
import { getQueryValue, getData, setPayload, getPayload } from '../../helper';

function loadScreen(siteID = null) {
    let screenType = getQueryValue('screen');

    try {
        if(screenType === 'list') return <InventoryListPage/>;
        if(screenType === 'addnew') return <AddInventoryPage />;
        if(screenType === 'transfer') return <TransferInventoryPage />;
        if(screenType === 'updatestock') return <UpdateStockPage />;
        if(screenType === 'warehouselisting') return <WarehouseListPage />;
        if(screenType === 'addnewwarehouse') return <AddWarehousePage />;
        if(screenType === 'categorylisting') return <CategoryListPage owner='inventory'/>;
        if(screenType === 'addcategory') return <AddCategoryPage owner='inventory'/>;
        if(screenType === 'brandlisting') return <BrandListPage owner='inventory'/>;
        if(screenType === 'addbrand') return <AddBrandPage owner='inventory'/>;
        if(screenType === 'unitlisting') return <UnitListPage owner='inventory'/>;
        if(screenType === 'addunit') return <AddUnitPage owner='inventory'/>;
        // if(screenType === 'details') return <ShopDetailsPage site_id={siteID}/>;
        // if(screenType === 'images') return <ShopImagesPage site_id={siteID}/>;
        // if(screenType === 'sections') return <ShopSectionsPage site_id={siteID}/>;
        // if(screenType === 'dashboard') return <ShopDashboardPage site_id={siteID}/>;
        return <InventoryListPage/>;
    } catch(err) {
        throw new Error(err.message);
    }
}

function pageOptions(siteID) {
    try {

        let options = {
            list: {
                label: "Available Stock",
                link: "/inventory?screen=list"
            },
            addnew: {
                label: "Add New Item",
                link: "/inventory?screen=addnew"
            },
            transfer: {
                label: "Transfer Stock",
                link: "/inventory?screen=transfer"
            },
            updatestock: {
                label: "Update Stock",
                link: "/inventory?screen=updatestock"
            },
            warehouselisting: {
                label: "Warehouses",
                link: "/inventory?screen=warehouselisting"
            },
            categorylisting: {
                label: "Categories",
                link: "/inventory?screen=categorylisting"
            },
            brandlisting: {
                label: "Brands",
                link: "/inventory?screen=brandlisting"
            },
            unitlisting: {
                label: "Units",
                link: "/inventory?screen=unitlisting"
            }
        };

        return options;
    } catch(err) {
        throw new Error(err.message);
    }
}

function pageContent() {
    try {
        const inventoryID = getQueryValue('inventory_id');

        // page options
        const sideBarOptions = pageOptions(inventoryID);
        
        return (
            <>
                <div id="wrapper">
                    <SideBarComponent options={sideBarOptions}/>
                    <div id="content-wrapper" className="d-flex flex-column">
                        {loadScreen(inventoryID)}
                    </div>
                </div>
            </>
        )
    } catch(err) {
        throw new Error(err.message);
    }
}

function InventoryPage() {

    return (
        <>
            <section id='dashboard-page'>
                <DashBoardViewComponent title='Inventory' content={pageContent()}/>
            </section>
        </>
    );
}

export default InventoryPage;