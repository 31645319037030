import React from 'react';
import FormControl from '../../components/FormControl';
import { ErrorToastComponent as ErrorToast } from '../../components/Toasts';
import { postData, getData, setPayload, getPayload } from '../../helper';

class UpdateKYCForm extends React.Component {
    constructor(props) {
        super();
        const userData = getPayload('user');
        this.submitHandler = this.submitHandler.bind(this);
        const self = this;
        this.state = {
            err_message: null,
            form: {
                onSubmit: self.submitHandler
            },
            fields: { 
                first_name: {
                    type: 'text',
                    placeholder: 'First name',
                    value: userData.first_name,
                    required: true,
                    onChange: self.onChangeHandler
                },
                last_name: {
                    type: 'text',
                    placeholder: 'Last name',
                    value: userData.last_name,
                    required: true,
                    onChange: self.onChangeHandler
                },
                dob: {
                    type: 'text',
                    placeholder: 'Date of Birth',
                    value: userData.dob,
                    required: true,
                    onChange: self.onChangeHandler
                },
                gender: {
                    type: 'select',
                    placeholder: 'Gender',
                    value: userData.gender,
                    required: true,
                    options: {
                        male: 'Male',
                        female: 'Female',
                        other: 'Other'
                    },
                    onChange: self.onChangeHandler
                },
                city: {
                    type: 'city',
                    placeholder: 'City',
                    value: userData.city,
                    required: true,
                    onChange: self.onChangeHandler
                },
                state: {
                    type: 'state',
                    placeholder: 'State',
                    value: userData.state,
                    required: true,
                    onChange: self.onChangeHandler
                },
                country: {
                    type: 'country',
                    placeholder: 'Country',
                    value: userData.country,
                    required: true,
                    onChange: self.onChangeHandler
                },
                about_me: {
                    type: 'text',
                    placeholder: 'About Me',
                    value: userData.about_me,
                    required: true,
                    onChange: self.onChangeHandler
                },
                pan_card_number: {
                    type: 'text',
                    placeholder: 'Pancard Number',
                    value: userData.pan_card.number,
                    required: true,
                    onChange: self.onChangeHandler
                },
                update_button: {
                    type: 'button',
                    label: 'Update Details',
                    btn_type: 'submit'
                }
            }
        };
    }

    onChangeHandler = (e) => {
        try {
            const fields = this.state.fields;
            fields[e.target.name].value = e.target.value;
            this.setState({fields: fields});
        } catch(err) {
            throw new Error(err.message);
        }
    }

    async submitHandler(payload) {
        try {
            const response = await postData('/users', payload, 'PUT');
            if(response.error && response.error.message) {
                this.setState({err_message: response.error.message});
            } else {
                if(response && response.data && response.data.updated) {
                    const userRes = await getData('/users');
                    if(userRes.data && userRes.data.user && userRes.data.user.info) {
                        setPayload(userRes.data.user.info, 'user');
                        window.location.href = '/user-kyc';
                    }
                }
            }

        } catch(err) {
            return new Error(err.message);
        }
    }

    render() {
        return (
            <>
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-4">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="p-5">
                                    <FormControl fields={this.state.fields} config={this.state.form}/>
                                    <ErrorToast message={this.state.err_message}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default UpdateKYCForm;