import React from 'react';
import { 
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import FormControl from '../../components/FormControl';
import { ErrorToastComponent as ErrorToast, SuccessToastComponent as SuccessToast } from '../../components/Toasts';
import { getData, postData } from '../../helper';
import { BasicCard } from '../../components/Card';
import { arrayMoveImmutable } from 'array-move';

class ShopSectionsPage extends React.Component {
    constructor(props) {
        super();
        this.submitHandler = this.submitHandler.bind(this);
        this.updateSections = this.updateSections.bind(this);
        this.loadSections = this.loadSections.bind(this);
        this.pageContent = this.pageContent.bind(this);
        const self = this;
        this.state = {
            sections: [],
            originalSections: [],
            err_message: null,
            form: {
                onSubmit: self.submitHandler
            },
            fields: { 
                label: {
                    label: 'Sections',
                    type: 'text',
                    placeholder: 'Sections',
                    value: '',
                    required: true,
                    onChange: self.onChangeHandler
                },
                create_button: {
                    type: 'button',
                    label: 'Add',
                    btn_type: 'submit'
                }
            }
        };
    }

    async componentDidMount() {
        try {

            // load sections
            this.loadSections();

        } catch(err) {
            throw new Error(err.message);
        }
    }

    async loadSections() {
        try {

            // load all sections
            const sections = await getData(`/site-sections/${this.props.site_id}`);
            if(sections && sections.data && sections.data.sections) {
                const availSections = [];
                for(const section of sections.data.sections) {
                    availSections.push(section.label);
                }
                this.setState({
                    sections: availSections,
                    originalSections: sections.data.sections
                });
            }

        } catch(err) {
            throw new Error(err.message);
        }
    }

    async removeSection() {
        try {
            console.log('hello?');
        } catch(err) {
            throw new Error(err.message);
        }
    }

    onChangeHandler = (e) => {
        try {
            const fields = this.state.fields;
            fields[e.target.name].value = e.target.value;
            this.setState({fields: fields});
        } catch(err) {
            throw new Error(err.message);
        }
    }

    async submitHandler(payload) {
        try {

            const siteID = this.props.site_id;
            const response = await postData(`/site-sections/${siteID}`, payload);
            if(response.error && response.error.message) {
                this.setState({err_message: response.error.message});
                return;
            } 
            this.setState({
                success_message: 'new section has been added'});
            this.loadSections();

        } catch(err) {
            return new Error(err.message);
        }
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        try {
            this.setState(({sections}) => ({
                sections: arrayMoveImmutable(sections, oldIndex, newIndex),
            }),() => {
                this.updateSections();
            });
        } catch(err) {
            throw new Error(err.message);
        }
    };

    async updateSections() {
        try {
            
            const { sections, originalSections } = this.state;

            const updatedSections = [];
            for(const position in sections) {
                const section = sections[position];
                for(const sectionData of originalSections) {
                    if(section === sectionData.label) {
                        updatedSections.push({
                            label: sectionData.label,
                            section_id: sectionData.section_id,
                            layout_order: parseInt(position) + 1
                        });
                    }
                }
            }
            const payload = {
                order: updatedSections
            };

            const apiPath = `/site-sections/${this.props.site_id}/order`;
            await postData(apiPath, payload, 'PUT');
            this.loadSections();

        } catch(err) {
            throw new Error(err.message);
        }
    }

    sectionList = () => {
        try {
            const { originalSections } = this.state;

            const sortables = Object.keys(originalSections).map((value) => (
                <SortableItem 
                key={`item-${originalSections[value].section_id}`} 
                index={value} 
                value={originalSections[value].label} 
                site_id={originalSections[value].site_id}
                section_id={originalSections[value].section_id}/>
            ));

            return (
                <>
                    <div className='sortable-item-list'>
                        <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                            {sortables}
                        </SortableContainer>
                    </div>
                </>
            );
        } catch(err) {
            return '';
        }
    }

    pageContent() {
        try {
            return (
                <>
                    <div className="row">
                        <div className="col-lg-5 col-md-4">
                            <div className="card o-hidden">
                                <div className="card-body p-0">
                                    <div className="p-5">
                                        <h3>Add New Section</h3>
                                        <FormControl fields={this.state.fields} config={this.state.form}/>
                                        <ErrorToast message={this.state.err_message}/>
                                        <SuccessToast message={this.state.success_message}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-8">
                            <div className="card o-hidden">
                                <div className="card-body p-0">
                                    <div className="p-5">
                                        <h3>Available Sections</h3>
                                        {this.sectionList()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } catch(err) {
            throw new Error(err.message);
        }
    }

    render() {
        return (
            <div className="container">
                <BasicCard title='Shop Sections' content={this.pageContent()}/>
            </div>
        );
    }
}

export default ShopSectionsPage;

const DragHandle = sortableHandle(() => <span className='selector'></span>);
const SortableItem = sortableElement((props) => (
    <div className='item stylized_item' section={props.section_id}>
        <DragHandle />
        {props.value}
        <button class="btn btn-danger btn-circle btn-sm btn-position" onClick={() => removeSection(props.site_id, props.section_id)}>
            <i class="fas fa-trash"></i>
        </button>
    </div>
));
const SortableContainer = sortableContainer(({children}) => {
    return <div>{children}</div>;
});

async function removeSection(siteID, sectionID) {
    try {

        const apiPath = `/site-sections/${siteID}/remove/${sectionID}`;
        await postData(apiPath, {}, 'DELETE');
        window.location.reload();
        
    } catch(err) {
        throw new Error(err.message);
    }
}