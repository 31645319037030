import React from 'react';
import FormControl from '../../components/FormControl';
import { ErrorToastComponent as ErrorToast, SuccessToastComponent as SuccessToast } from '../../components/Toasts';
import { postFormData } from '../../helper';
import { store } from './common';

class AddImageForm extends React.Component {
    constructor(props) {
        super();
        this.submitHandler = this.submitHandler.bind(this);
        const self = this;
        this.state = {
            err_message: null,
            form: {
                onSubmit: self.submitHandler
            },
            fields: { 
                icon: {
                    type: 'file',
                    placeholder: 'Shop Logo',
                    value: '',
                    required: true,
                    onChange: self.onChangeHandler
                },
                banner_image: {
                    type: 'file',
                    placeholder: 'Banner Image',
                    value: '',
                    required: true,
                    onChange: self.onChangeHandler
                },
                update_button: {
                    type: 'button',
                    label: 'Update',
                    btn_type: 'submit'
                }
            }
        };
    }

    onChangeHandler = (e) => {
        try {
            const fields = this.state.fields;
            fields[e.target.name].value = e.target.files[0];
            this.setState({fields: fields});
        } catch(err) {
            throw new Error(err.message);
        }
    }

    async submitHandler(payload) {
        try {

            const formdata = new FormData();
            formdata.append("icon", payload.icon, payload.icon.name);
            formdata.append("banner_image", payload.banner_image, payload.banner_image.name);

            const siteID = this.props.site_id;
            const response = await postFormData(`/sites/${siteID}/upload`, formdata, 'PUT');
            if(response.error && response.error.message) {
                this.setState({err_message: response.error.message});
                return;

            } 
            this.setState({success_message: 'images updated'});
            store(siteID, response);

        } catch(err) {
            return new Error(err.message);
        }
    }

    render() {
        return (
            <>
                <div className="card o-hidden">
                    <div className="card-body p-0">
                        <div className="p-5">
                            <h3>Shop Images</h3>
                            <FormControl fields={this.state.fields} config={this.state.form}/>
                            <ErrorToast message={this.state.err_message}/>
                            <SuccessToast message={this.state.success_message}/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AddImageForm;