import React from 'react';
import DashBoardViewComponent from '../../components/DashBoardView';
import UpdateUserKYCForm from '../User/UpdateKYCForm';

function pageContent() {
    try {
        return (
            <>
                <UpdateUserKYCForm/>
            </>
        );
    } catch(err) {
        return '';
    }
}

function UserKYCPage() {
    return (
        <>
            <DashBoardViewComponent title='Update KYC Information' content={pageContent()}/>
        </>
    );
}

export default UserKYCPage;