import React from 'react';
import { loggedInRedirection } from '../../helper';
import LoginForm from './LoginForm';

function LoginPage() {
    const loginRedirection = loggedInRedirection();
    if(loginRedirection) return '';
    return (
        <>
            <section id='login-page'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-8 col-md-4">
                            <div className="card o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="p-5">
                                                <div className="text-center">
                                                    <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                                </div>
                                                <LoginForm />
                                                <hr/>
                                                <div className="text-center">
                                                    <a className="small" href="/forgot-password">Forgot Password?</a>
                                                </div>
                                                <div className="text-center">
                                                    <a className="small" href="/register">Create an Account!</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LoginPage;