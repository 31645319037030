import React from 'react';
import FormControl from '../../components/FormControl';
import { ErrorToastComponent as ErrorToast, SuccessToastComponent as SuccessToast } from '../../components/Toasts';
import { postData } from '../../helper';
import { store, fetch } from './common';

class ContactDetailsForm extends React.Component {
    constructor(props) {
        super();
        this.submitHandler = this.submitHandler.bind(this);
        const self = this;
        const siteData = fetch(props.site_id);
        this.state = {
            err_message: null,
            form: {
                onSubmit: self.submitHandler
            },
            fields: { 
                mobile_number: {
                    label: 'Mobile Number',
                    type: 'text',
                    placeholder: 'Contact Mobile Number',
                    value: siteData?.contact_details?.mobile_number,
                    required: true,
                    onChange: self.onChangeHandler
                },
                contact_name: {
                    label: 'Contact Name',
                    type: 'text',
                    placeholder: 'Name of the Contact Person',
                    value: siteData?.contact_details?.contact_name,
                    required: true,
                    onChange: self.onChangeHandler
                },
                create_button: {
                    type: 'button',
                    label: 'Update',
                    btn_type: 'submit'
                }
            }
        };
    }

    onChangeHandler = (e) => {
        try {
            const fields = this.state.fields;
            fields[e.target.name].value = e.target.value;
            this.setState({fields: fields});
        } catch(err) {
            throw new Error(err.message);
        }
    }

    async submitHandler(payload) {
        try {
            const siteID = this.props.site_id;
            const requestPayload = {
                contact: payload
            }
            const response = await postData(`/sites/${siteID}/details`, requestPayload, 'PUT');
            if(response.error && response.error.message) {
                this.setState({err_message: response.error.message});
                return;

            } 
            this.setState({success_message: 'contact details updated'});
            store(siteID, response);

        } catch(err) {
            return new Error(err.message);
        }
    }

    render() {
        return (
            <>
                <div className="card o-hidden">
                    <div className="card-body p-0">
                        <div className="p-5">
                            <h3>Contact Details</h3>
                            <FormControl fields={this.state.fields} config={this.state.form}/>
                            <ErrorToast message={this.state.err_message}/>
                            <SuccessToast message={this.state.success_message}/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ContactDetailsForm;